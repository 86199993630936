import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { SignUp } from "../../api";
import lightLogo from "../../assets/images/logolight.png";
import "./SignupPage.scss";

const SignupPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [message, setMessage] = useState<String>("");
  const [log_spinner, setLog_spinner] = useState<any>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const registerUser = (data: any) => {
    if (checked) {
      setMessage("");
      setLog_spinner(<div className="loader"></div>);
      SignUp(data)
        .then((response) => {
          setLog_spinner("");
          if (response.data.oblastjwttoken) {
            localStorage.setItem(
              "user",
              `{oblastjwttoken:${response.data.oblastjwttoken}}`
            );
            navigate("/verifyemail", {
              state: { data: data, token: response.data.oblastjwttoken },
            });
            reset();
          } else {
            // alert(response.data);
            setMessage(response.data);
          }
        })
        .catch((error) => {
          setLog_spinner("");
          setMessage(error);
          console.log("error", error);
        });
    } else {
      alert("Please accept the terms and conditions");
    }
  };

  return (
    <div className="signup-page">
      <div className="signup-page-section">
        <div className="signup-page-section-form">
          <div className="signup-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
          </div>
          <form className="form_signup" onSubmit={handleSubmit(registerUser)}>
            <label className="label_register">
              <input
                autoComplete="off"
                className="input_register"
                type="text"
                placeholder="Email"
                {...register("email_or_phone", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email",
                  },
                })}
              />
            </label>
            <label className="label_register">
              <input
                autoComplete="on"
                className="input_register"
                type="text"
                placeholder="Username"
                {...register("username", {
                  required: "Username is required",
                })}
              />
            </label>
            <label className="label_register">
              {showPassword && (
                <span onClick={() => setShowPassword(!showPassword)}>
                  look pass
                </span>
              )}
              <input
                autoComplete="on"
                className="input_register"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
              />
            </label>
            <label className="label_register">
              {confirmPassword && (
                <span onClick={() => setConfirmPassword(!confirmPassword)}>
                  look pass
                </span>
              )}
              <input
                autoComplete="on"
                className="input_register"
                type={confirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                {...register("confirm", {
                  required: "Confirm Password is required",
                })}
              />
            </label>
            <label className="remember_select">
              I accept the
              <span style={{ fontWeight: "bold" }}> terms and conditions</span>
              <input
                type="checkbox"
                onChange={(e) => setChecked(e.target.checked)}
              />
              <span className="checkmark"></span>
            </label>
            <div className="button_section">
              <button className="login_button" type="submit">
                {log_spinner}
                Next
              </button>
            </div>
          </form>
          <div className="message-error">{message}</div>
        </div>
        <div className="login-page-section-register">
          <div>
            Already have an account? <Link to={"/"}>Sign In</Link>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
