export const DownloadIcon = () => {
  return (
    <svg
      aria-label="Account History: See the changes you've made to your account since it was created."
      className="actions-icons"
      color="rgb(38, 38, 38)"
      fill="rgb(38, 38, 38)"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
    >
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="11.914"
        x2="11.914"
        y1="15.195"
        y2="2"
      ></line>
      <polyline
        fill="none"
        points="16.013 11.095 11.914 15.195 7.814 11.095"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      ></polyline>
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        x1="3.277"
        x2="20.55"
        y1="22"
        y2="22"
      ></line>
    </svg>
  );
};
