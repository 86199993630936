import {useEffect, useState} from "react";
import "./MiniUserModal.scss";
import defaultAvatar from "../../../assets/images/default-avatar.jpg";
import {CountFollowageAndLikes, DisplayProfileProducts} from "../../../api";
import {BarLoader} from "react-spinners";
import {AddFollow} from "../../icons/AddFollow";
import {useNavigate} from "react-router-dom";

interface Props {
  setOpenMiniUserModal: (openMiniUserModal: boolean) => void;
  user: any;
  coords: any;
  lastCoords: any;
}
interface FollowInfo {
  follows_me: number;
  i_follow: number;
  likes: number;
}

const MiniUserModal = ({setOpenMiniUserModal, user, coords, lastCoords}: Props) => {
  const [followInfo, setFollowInfo] = useState<FollowInfo | null>(null);
  const [products, setProducts] = useState<[]>([]);
  const [productsLength, setProductsLength] = useState<number>(0);
  const navigate = useNavigate();
  const [modalPosition, setModalPosition] = useState<object>({
    top: coords.y + 20,
    left: coords.x - 65,
  });
  useEffect(() => {
    if (lastCoords.y !== 0 && lastCoords.y !== 0) {
      coords = lastCoords;
    }
  }, [coords]);

  useEffect(() => {
    const fetchData = async () => {
      await CountFollowageAndLikes(user?.userid)
        .then((res) => {
          setFollowInfo(res.data);
        })
        .catch((error) => {
          console.log(error);
          console.log(error, "error");
        });
      await DisplayProfileProducts(user?.userid, 0)
        .then((res) => {
          setProducts(res.data.slice(0, 3));
          setProductsLength(res.data.length);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const myY = coords.y + 20;
    const winY = window.innerHeight;
    if (winY - myY > 300) {
      setModalPosition({
        top: coords.y + 20,
        left: coords.x - 65,
      });
    } else {
      setModalPosition({
        top: coords.y - 300,
        left: coords.x - 65,
      });
    }
  }, [coords]);

  return (
    <div className="modal aaaa" style={modalPosition} onMouseEnter={() => setOpenMiniUserModal(true)} onMouseLeave={() => setOpenMiniUserModal(false)}>
      <div className="avatar_info" onClick={() => navigate(/u/ + user?.username)}>
        {user?.image ? (
          <div>
            <img src={user?.image} alt="img" />
          </div>
        ) : (
          <div>
            <img src={defaultAvatar} alt="avatar" />
          </div>
        )}
        <p>{user?.username}</p>
      </div>
      <div className="account_info">
        {followInfo ? (
          <div>
            <div>
              <h5>{productsLength}</h5>
              <span>publications</span>
            </div>
            <div>
              <h5>{followInfo?.follows_me}</h5>
              <span>subscribers</span>
            </div>
            <div>
              <h5>{followInfo?.i_follow}</h5>
              <span>subscriptions</span>
            </div>
          </div>
        ) : (
          <div>
            <BarLoader color="#0095F6" height={4} speedMultiplier={1} width={175} />
          </div>
        )}
      </div>
      <div className="publications_info">
        {products?.map((elm: any) => {
          return (
            <img
              src={elm?.Images}
              key={elm?._id}
              alt=""
              onClick={() => {
                navigate("/product/" + elm?._id);
              }}
            />
          );
        })}
      </div>
      <div className="button_subscribe">
        <button className="btn-subscribe">
          <AddFollow />
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default MiniUserModal;
