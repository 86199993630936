import React, { useState, useEffect } from "react";
import "../VerifyEmailPage/VerifyEmailPage.scss";
import lightLogo from "../../assets/images/logolight.png";
import { useLocation, useNavigate } from "react-router-dom";
import { VerifyYourEmail } from "../../api";

const VerifyTokenPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.data?.email_or_phone;

  const [error, setError] = useState<string>("Verifying Account...");

  const currentLocation = window.location.href.split("?verifytoken=");

  const verify_response = () => {
    let tokenx: string = "";
    if (currentLocation.length > 0) {
      tokenx = currentLocation[1];
      VerifyYourEmail(tokenx)
        .then((response) => {
          navigate("/");
        })
        .catch((error) => {
          console.log(error);
          setError("Unauthorized verification");
        });
    }
  };

  useEffect(() => {
    try {
      verify_response();
    } catch (ex) {
      //random comment
      return;
    }
  }, []);

  return (
    <div className="verify-page">
      <div className="verify-page-section">
        <div className="verify-page-section-form">
          <div className="verify-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
            <h3>Verifying your email address</h3>
          </div>
          <div className="verify-page-section-form-description">
            <p>
              You’re almost there! We're verifying the email{" "}
              <b className="user_email">{email}</b>
            </p>
            <p>No action needed on your part</p>
            <p>If this brings up an error then simply refresh the page</p>
          </div>
          <div className="verify-page-section-form-action">
            <p>{error}</p>
          </div>
          <p>
            Need help? <b> Contact Us</b>
          </p>
        </div>
        <div className="verify-page-section-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate("/")}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};
export default VerifyTokenPage;
