import React from "react";
import "./VerifyEmailPage.scss";
import lightLogo from "../../assets/images/logolight.png";
import { useLocation, useNavigate } from "react-router-dom";
import { VerifyYourEmail } from "../../api";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.data?.email_or_phone;
  const token = location?.state?.token;

  return (
    <div className="verify-page">
      <div className="verify-page-section">
        <div className="verify-page-section-form">
          <div className="verify-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
            <h3>Register New Account</h3>
          </div>
          <div className="verify-page-section-form-description">
            <p>
              You’re almost there! We Sent an email to{" "}
              <b className="user_email">{email}</b>
            </p>
            <p>Just click on the link in that email to complete your signup.</p>
            <p>
              If you don’t see it, you may need to <b>check your spam</b>{" "}
              folder.
            </p>
          </div>
          <p>
            Need help? <b> Contact Us</b>
          </p>
        </div>
        <div className="verify-page-section-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate("/")}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};
export default VerifyEmailPage;
