import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import {
  DisplayProfileProducts,
  SearchUsernameFirst,
  FollowUser,
  UnfollowUser,
} from "../../api";
import "./FriendsProfile.scss";
import { ThreeDotsIcon } from "../../components/icons/ThreeDotsIcon";
import ProfileSettingsModal from "../../components/modals/ProfileSettingsModal/ProfileSettingsModal";
import ProductDetailsModal from "../../components/modals/ProductDetailsModal/ProductDetailsModal";
import { useAuthContext } from "../../contexts/AuthContext";

const FriendsProfile = () => {
  const { currentUser, isLoggedIn } = useAuthContext();
  const [user, setUser] = useState<any>({});
  const [products, setProducts] = useState<any>([]);
  const [follows, setFollows] = useState<any>({});
  const params = useParams();
  const ref = useRef<any>(null);
  const [openProfileSettingsModal, setOpenProfileSettingsModal] =
    useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [openProductModal, setOpenProductModal] = useState<boolean>(false);
  const [following, setFollowing] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();

  console.log("current-user: ", currentUser);
  console.log("user of friends profile: ", user);

  const fetchData = (skip: number, userx: any) => {
    if (!isLoggedIn && page > 0) {
      navigate("/");
      return;
    }
    DisplayProfileProducts(userx.userid, skip)
      .then((res) => {
        console.log("displayprofileproducts", res);
        if (res.data !== "User hasn't added any products") {
          let prods: any = [...products, ...res.data];
          setProducts(prods);
        }
      })
      .catch((err) => {
        console.log(err, "Product not found");
      });
  };

  useEffect(() => {
    const fetchDataI = async (logged: boolean) =>
      await SearchUsernameFirst(params?.username)
        .then(async (res) => {
          console.log("firing res.data", res.data);
          setUser(res.data.user);
          setFollows(res.data.follow);
          fetchData(page, res.data.user);
          if (res.data.doifollow === "Yes") {
            setFollowing(true);
          }
        })
        .catch((err) => {
          console.log(err, "User Not Found");
        });
    if (currentUser !== null) {
      fetchDataI(true);
    } else {
      fetchDataI(false);
    }
  }, []);

  const handleFollow = async () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    await FollowUser({ userid: currentUser.user_ID, userifollow: user.userid })
      .then((res) => setFollowing(true))
      .catch((err) => {
        console.log(err);
      });

    let followsx = follows;
    followsx.follows_me++;
    setFollows(followsx);
  };

  const handleUnFollow = async () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    await UnfollowUser({
      userid: currentUser.user_ID,
      userifollow: user.userid,
    })
      .then((res) => setFollowing(false))
      .catch((err) => {
        console.log(err);
        setFollowing(false);
      });

    let followsx = follows;
    followsx.follows_me--;
    setFollows(followsx);
  };

  const handleOpenProfileSettingsModal = () => {
    setOpenProfileSettingsModal(true);
  };

  const handleProductClick = (index: string) => {
    setOpenProductModal(true);
    setActiveProductId(index);
  };

  const handleScroll = (e: any) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom) {
      console.log("reached the bottom scroll");
      let new_page = page + 1;
      let skip = new_page * 15;
      setPage(new_page);
      fetchData(skip, user);
    }
  };

  const convertLinks = (desc: string) => {
    if (!desc) return "";
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return desc.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
  };

  const description = convertLinks(user?.description);

  return (
    <div className="friends-profile" key={location.pathname}>
      <div className="friends-profile-container">
        <div className="friends-letters">
          <p>{user?.username}</p>
        </div>

        <div
          className="friends-share-section"
          ref={ref}
          onScroll={handleScroll}
        >
          <div className="friends-header-section">
            <div className="friends-profile-info">
              <div className="friends-top-row">
                <div className="friends-profile-pic-section">
                  <img
                    src={user?.image === "" ? defaultAvatar : user?.image}
                    alt="user-avatar"
                  />
                </div>
                <div className="friends-likes-and-stuff">
                  <div className="friends-lff">
                    <span className="friends-count">
                      {follows ? follows?.likes : 0}
                    </span>
                    <span className="friends-text">likes</span>
                  </div>
                  <div className="friends-lff">
                    <span className="friends-count">
                      {follows ? follows?.follows_me : 0}
                    </span>
                    <span className="friends-text">followers</span>
                  </div>
                  <div className="friends-lff">
                    <span className="friends-count">
                      {follows ? follows?.i_follow : 0}{" "}
                    </span>
                    <span className="friends-text">following</span>
                  </div>
                </div>
              </div>
              <div className="friends-middle-row">
                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </div>
              </div>
              <div className="friends-bottom-row">
                {!following ? (
                  <div className="follow-button" onClick={() => handleFollow()}>
                    <span>Follow</span>
                  </div>
                ) : (
                  <div
                    className="unfollow-button"
                    onClick={() => handleUnFollow()}
                  >
                    <span>Unfollow</span>
                  </div>
                )}
                <div
                  className="settings"
                  onClick={() => handleOpenProfileSettingsModal()}
                >
                  <ThreeDotsIcon />
                </div>
              </div>
            </div>
          </div>
          {products ? (
            <div className="friends-publications_body">
              {products?.map((elm: any) => {
                return (
                  <div
                    className="friends-publication_div"
                    key={elm._id}
                    onClick={() => handleProductClick(elm._id)}
                  >
                    {/* <div className="friends-hovered_div">
                      <ul>
                        <li>
                          <WhiteHeartIcon />
                        </li>
                        <li>Likes</li>
                        <li>
                          <CommentsIconWhite />
                        </li>
                        <li> {elm.comment_count || 0}</li>
                      </ul>
                    </div> */}
                    <div className="friends-image-container">
                      <img src={elm?.Images} key={elm?._id} alt="product" />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="">no publications</div>
          )}
        </div>
      </div>
      {openProfileSettingsModal && (
        <ProfileSettingsModal
          setOpenProfileSettingsModal={setOpenProfileSettingsModal}
          user={user}
          setUser={setUser}
        />
      )}
      {openProductModal && (
        <ProductDetailsModal
          productId={activeProductId}
          setOpenModal={setOpenProductModal}
          setOpenSettingsModal={setOpenSettingsModal}
          openSettingsModal={openSettingsModal}
        />
      )}
    </div>
  );
};
export default FriendsProfile;
