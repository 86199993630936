import React from "react";
import lightLogo from "../../assets/images/logolight.png";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckEmailForgotPass } from "../../api";
import { useState, useEffect } from "react";
import "../../assets/scss/Animations.scss";

const ForgetPasswordSecondPage = () => {
  const [button, setButton] = useState<any>("Resend Email");
  const [can_resend, setCan_Resend] = useState<boolean>(true);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.data.email_or_phone;

  const resendEmail = () => {
    if (can_resend) {
      setButton(<div className="loader"></div>);
      CheckEmailForgotPass(location.state?.data)
        .then((response: any) => {
          setButton("Can Resend Again in 30 seconds");
          if (response.data) {
            console.log(
              response.data,
              "response from server at checkemailforgotpass"
            );
            setSuccessMessage("Please Check your Junk Folder");
          }
        })
        .catch((error) => {
          console.log(error);
          setButton("Can Resend Again in 30 seconds");
        });
      setCan_Resend(false);
      setTimeout(() => {
        setCan_Resend(true);
        console.log("timeout finished");
        setButton("Resend Email");
      }, 30000);
    }
  };

  return (
    <div className="verify-page">
      <div className="verify-page-section">
        <div className="verify-page-section-form">
          <div className="verify-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
            <h3>Password Recovery</h3>
          </div>
          <div className="verify-page-section-form-description">
            <p>
              If an account with this email exists, we have sent you the link
              to:
            </p>
            <p>
              <b className="user_email">{email}</b>
            </p>
            <p>
              <b className="user_email">{successMessage}</b>
            </p>
            <p>Please, follow the instruction to recover your account.</p>
          </div>
          <div className="verify-page-section-form-action">
            <p>Still can’t find the email?</p>
            <button type="button" className="verify-btn" onClick={resendEmail}>
              {button}
            </button>
          </div>
          <p>
            Need help? <b> Contact Us</b>
          </p>
        </div>
        <div className="verify-page-section-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate("/")}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordSecondPage;
