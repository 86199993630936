import React, { useEffect, useState } from "react";
import "./SettingsPage.scss";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import { useAuthContext } from "../../contexts/AuthContext";
import { UpdateUserInfo, UploadProduct } from "../../api";
import { useNavigate } from "react-router-dom";

const SettingsPage = () => {
  const { currentUser, setCurrentUser, isLoggedIn } = useAuthContext();
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [textareaValue, setTextareaValue] = useState<string>("");
  const [newLines, setNewLines] = useState<number>(0);
  const [image, setImage] = useState<any>(null);
  const [imageURL, setImageURL] = useState<any>("");
  const [active, setActive] = useState<boolean>(false);
  const [savedInfo, setSavedInfo] = useState<string>("");
  const navigate = useNavigate();

  const getImage = (file: any) => {
    if (file.length) {
      setImage(file[0]);
      const formData = new FormData();
      setSavedInfo("uploading, please wait...");
      formData.append("file", file[0]);
      UploadProduct(formData)
        .then((res) => {
          setImageURL(res.data);
          setSavedInfo("uploaded! You can save now");
        })
        .catch((er) => {
          setSavedInfo(`${er} error while uploading picture`);
        });
    }
  };

  const SelectTab = (index: any) => {
    setSelectedTab(index);
  };

  const updateSettings: any = async () => {
    let data = {
      userid: currentUser.user_ID,
      description: textareaValue,
      image: imageURL ? imageURL : currentUser.image,
    };
    setSavedInfo("Updating Information...");
    await UpdateUserInfo(data)
      .then((res) => {
        setTextareaValue(res.data.description);
        setSavedInfo("Saved Info!");
        const userData = {
          user_ID: res?.data.userid,
          username: res?.data.username,
          image: res?.data.image,
          description: res?.data.description,
        };
        setCurrentUser(userData);
      })
      .catch((er) => {
        setSavedInfo(`${er} error while updating`);
      });
  };

  useEffect(() => {
    if (currentUser) {
      setTextareaValue(currentUser.description);
    }

    if (!isLoggedIn) {
      navigate("/");
      return;
    }
  }, [currentUser]);

  const countNewLines = (event: any) => {
    let count_new_lines: number = 0;
    let bio: string = event.target.value;
    let new_bio: string = "";
    for (let i = 0; i < bio.length; i++) {
      if (bio[i] === "\n") {
        count_new_lines++;
      }
      if (bio[i] === "\n" && count_new_lines < 5) {
        new_bio = new_bio + bio[i];
      } else if (bio[i] === "\n" && count_new_lines >= 5) {
        //don't add it
      } else if (bio[i] !== "\n") {
        new_bio = new_bio + bio[i];
      }
    }
    // console.log("new_bio: ", new_bio);
    setTextareaValue(new_bio);
  };

  return (
    <div className="general-page-settings">
      <div className="settings-page">
        <div className="settings-page-container">
          <div className="right-section">
            <div>
              <h1>Edit Profile</h1>
              <div className="edit-profile">
                <div className="edit-row-1">
                  <div className="avatar">
                    {image ? (
                      <img src={URL.createObjectURL(image)} alt="img"></img>
                    ) : (
                      <img
                        src={currentUser?.image || defaultAvatar}
                        alt="uset-avatar"
                      />
                    )}
                  </div>
                  <div>
                    <h3>{currentUser?.username}</h3>
                    <label className="upload-file">
                      Change profile photo
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setActive(!active);
                          getImage(e.target.files);
                        }}
                      />
                    </label>
                    <p className="save-info">{savedInfo}</p>
                  </div>
                </div>
                <div className="edit-row-1">
                  <div className="left-part">
                    <label>About Me</label>
                  </div>
                  <div>
                    <textarea
                      maxLength={150}
                      value={textareaValue ? textareaValue : ""}
                      onChange={countNewLines}
                    />
                    <p>
                      {textareaValue ? textareaValue.length : 0}/150 - max 4
                      lines
                    </p>
                  </div>
                </div>

                <div className="edit-row-1">
                  <div className="left-part"></div>
                  <div>
                    <button onClick={updateSettings}>Send</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SettingsPage;
