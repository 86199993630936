export const AccountHistoryIcon = () => {
  return (
    <svg
      aria-label="Account History: See the changes you've made to your account since it was created."
      className="actions-icons"
      color="rgb(38, 38, 38)"
      fill="rgb(38, 38, 38)"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
    >
      <rect
        fill="none"
        height="18.669"
        rx="2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        width="20"
        x="2"
        y="3.331"
      ></rect>
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        x1="2"
        x2="22"
        y1="7.778"
        y2="7.778"
      ></line>
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        x1="6.666"
        x2="6.666"
        y1="2"
        y2="4.665"
      ></line>
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        x1="17.555"
        x2="17.555"
        y1="2"
        y2="4.667"
      ></line>
      <circle cx="12" cy="12.666" r="1.001"></circle>
      <circle cx="12" cy="17.112" r="1.001"></circle>
      <circle cx="17" cy="12.666" r="1.001"></circle>
      <circle cx="17" cy="17.112" r="1.001"></circle>
      <circle cx="7" cy="12.666" r="1.001"></circle>
      <circle cx="7" cy="17.112" r="1.001"></circle>
    </svg>
  );
};
