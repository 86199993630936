import React, { useEffect, useState } from "react";
import "./MessagePage.scss";
import { EditIcon } from "../../components/icons/EditIcon";
import { MessageIconBig } from "../../components/icons/MessageIconBig";
import { MessageIcon } from "../../components/icons/MessageIcon";
import NewMessageModal from "../../components/modals/NewMessageModal/NewMessageModal";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
const MessagePage = () => {
  const [openNewMessageModal, setOpenNewMessageModal] =
    useState<boolean>(false);
  const handleSettingsClick = () => {
    setOpenNewMessageModal(true);
  };
  const [usersId, setUsersId] = useState<any[]>([]);
  return (
    <div className="general-page">
      <div className="users-section">
        <div className="header-section">
          <h4>header</h4>
          <div
            onClick={() => {
              handleSettingsClick();
            }}
          >
            <EditIcon />
          </div>
        </div>
        <div className="middle-section">
          <p>Messages</p>
          <span>Requests</span>
        </div>
        {!usersId.length ? (
          <div className="message-section">
            <span>No messages found.</span>
          </div>
        ) : (
          <>
            {usersId.map((user, index) => {
              return (
                <div key={index} className="message-row">
                  {user.map((u: any) => {
                    return (
                      <div key={u.uid} className="message-block">
                        <div className="image_block">
                          {u.image ? (
                            <img src={u.image} alt="user" />
                          ) : (
                            <img src={defaultAvatar} alt="avatar"></img>
                          )}
                        </div>
                        <h1>{u.name}</h1>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="my-messages">
        <MessageIconBig />
        <p>your messages</p>
        <span>Send private photos and messages to a friend or group</span>
        <button onClick={() => handleSettingsClick()}>Send a message</button>
      </div>
      {openNewMessageModal && (
        <NewMessageModal
          setOpenNewMessageModal={setOpenNewMessageModal}
          usersId={usersId}
          setUsersId={setUsersId}
        />
      )}
    </div>
  );
};

export default MessagePage;
