import EmojiPicker from "emoji-picker-react";
import "./Emoji.scss";
function Emoji({ addEmoji }: any) {
  return (
    <div className="emojis">
      <EmojiPicker
        onEmojiClick={(e) => {
          e.emoji.length < 3 ? addEmoji(e.emoji + " ") : addEmoji(e.emoji);
        }}
      />
    </div>
  );
}
export default Emoji;
