import React, { useState } from "react";
import "../modals/ProductDetailsModalSelf/ProductDetailsModalSelf.scss";
import { DeleteCommentsOnMyProducts } from "../../api";
import { useNavigate } from "react-router-dom";

interface Props {
  comment_id?: string;
  comment_username?: string;
  comment_text?: string;
  onDeleteRefresh: () => void;
}

const OwnProfileComments = ({
  comment_id,
  comment_username,
  comment_text,
  onDeleteRefresh,
}: Props) => {
  const [removal, setRemoval] = useState<string>("x");
  const navigate = useNavigate();

  const handleCommentDelete = () => {
    if (removal === "x") {
      setRemoval("delete");
    } else if (removal === "delete") {
      setRemoval("x");
      // setTimeout(() => {
      //   setRemoval("deleted\nrefesh");
      // }, 5000);
      DeleteCommentsOnMyProducts(comment_id)
        .then((res: any) => {
          //refresh here
          onDeleteRefresh();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  return (
    <div key={comment_id}>
      <div className="comment-row">
        <div>
          <span
            onClick={() => {
              navigate(`/u/${comment_username}`);
              window.location.reload();
            }}
            className="username"
          >
            {comment_username}
          </span>
          <span className="comment">{comment_text}</span>
        </div>
      </div>
      <div onClick={handleCommentDelete} className="remove-comment">
        [{removal}]
      </div>
    </div>
  );
};

export default OwnProfileComments;
