import React,{ createContext, useContext, useState } from "react";

export const ProductContext = createContext();

const ProductContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [ownProducts, setOwnProducts] = useState([]);
  const [profileProducts, setProfileProducts] = useState([]);
  const [product, setProduct] = useState({});
  return (
    <ProductContext.Provider
      value={{
        products,
        setProducts,
        product,
        setProduct,
        ownProducts,
        setOwnProducts,
        profileProducts,
        setProfileProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

const useProductContext = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProductContext was used outside of its Provider");
  }
  return context;
};

export { ProductContextProvider, useProductContext };
