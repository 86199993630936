import React, { useState } from "react";
import "./ActionsPage.scss";
import { InteractionsIcon } from "../../components/icons/InteractionsIcon";
import { PhotoIcon } from "../../components/icons/PhotoIcon";
import { AccountHistoryIcon } from "../../components/icons/AccountHistoryIcon";
import { DownloadIcon } from "../../components/icons/DownloadIcon";
import { HeartIcon } from "../../components/icons/HeartIcon";
import { CommentIcon } from "../../components/icons/CommentsIcon";
import { AnswerIcon } from "../../components/icons/AnswersIcon";
import { ReviewsIcon } from "../../components/icons/ReviewsIcon";
import { PublicationIcon } from "../../components/icons/PublicationIcon";
import { ReelsIcon } from "../../components/icons/ReelsIcon";
import warning from "../../assets/images/02ffe4dfdf20.png";

const ActionsPage = () => {
  const [actionsTabs, setActionsTab] = useState<number>(1);
  const [selectInteractionsTab, setSelectInteractionsTab] = useState<number>(1);
  const [selectPhotoTab, setSelectPhotoTab] = useState<number>(1);
  const SelectActionTabs = (index: any) => {
    setActionsTab(index);
  };

  const SelectInteractionsTabs = (index: any) => {
    setSelectInteractionsTab(index);
  };

  const SelectPhotoTabs = (index: any) => {
    setSelectPhotoTab(index);
  };

  return (
    <div className="action-page">
      <div className="action-page-container">
        <div className="left-section">
          <div className="title">
            <h4>Your Actions</h4>
          </div>
          <div className="sidebar-section">
            <div
              onClick={() => SelectActionTabs(1)}
              className={actionsTabs === 1 ? "active" : ""}
            >
              <InteractionsIcon />
              <div>
                <p>Interactions</p>
                <span>
                  View and delete likes, comments and other interactions.
                </span>
              </div>
            </div>
            <div
              onClick={() => SelectActionTabs(2)}
              className={actionsTabs === 2 ? "active" : ""}
            >
              <PhotoIcon />
              <div>
                <p>Photo and video</p>
                <span>
                  View, archive or delete photos and videos you've shared.
                </span>
              </div>
            </div>
            <div
              onClick={() => SelectActionTabs(3)}
              className={actionsTabs === 3 ? "active" : ""}
            >
              <AccountHistoryIcon />
              <div>
                <p>Account History</p>
                <span>
                  Review the changes you've made to your account since it was
                  created.
                </span>
              </div>
            </div>
            <div
              onClick={() => SelectActionTabs(4)}
              className={actionsTabs === 4 ? "active" : ""}
            >
              <DownloadIcon />
              <div>
                <p>Download information</p>
                <span>
                  Download a copy of the information you shared with Instagram.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="right-section">
          {actionsTabs === 1 && (
            <div className="tabs-section">
              <div className="tabs">
                <div
                  onClick={() => SelectInteractionsTabs(1)}
                  className={selectInteractionsTab === 1 ? "active" : ""}
                >
                  <HeartIcon></HeartIcon>
                  <p>LIKES</p>
                </div>
                <div
                  onClick={() => SelectInteractionsTabs(2)}
                  className={selectInteractionsTab === 2 ? "active" : ""}
                >
                  <CommentIcon></CommentIcon>
                  <p>COMMENTS</p>
                </div>
                <div
                  onClick={() => SelectInteractionsTabs(3)}
                  className={selectInteractionsTab === 3 ? "active" : ""}
                >
                  <AnswerIcon></AnswerIcon>
                  <p>ANSWERS TO STORIES</p>
                </div>
                <div
                  onClick={() => SelectInteractionsTabs(4)}
                  className={selectInteractionsTab === 4 ? "active" : ""}
                >
                  <ReviewsIcon></ReviewsIcon>
                  <p>REVIEWS</p>
                </div>
              </div>
              <div className="content">
                {selectInteractionsTab === 1 && (
                  <div className="likes-content">
                    <div className="sorting">
                      <h4>From new to old</h4>
                      <button>Sort and filter</button>
                    </div>
                    <div>
                      <span>Choose</span>
                    </div>
                  </div>
                )}
                {selectInteractionsTab === 2 && (
                  <div className="comments-content">
                    <img src={warning} alt="img"></img>
                    <h1>You have not left any comments yet</h1>
                    <span>
                      The photo or video that you commented on will be displayed
                      here.
                    </span>
                  </div>
                )}
                {selectInteractionsTab === 3 && (
                  <div className="answers-content comments-content">
                    <img src={warning} alt="img"></img>
                    <h1>You haven't replied to stickers yet</h1>
                    <span>Stickers that you reply to will be shown here.</span>
                  </div>
                )}
                {selectInteractionsTab === 4 && (
                  <div className=" comments-content reviews">
                    <img src={warning} alt="img"></img>
                    <h1>No feedback action</h1>
                    <span>
                      The reviews you submit to Instagram will be shown here.
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          {actionsTabs === 2 && (
            <div className="tabs-section">
              <div className="tabs">
                <div
                  onClick={() => SelectPhotoTabs(1)}
                  className={selectPhotoTab === 1 ? "active" : ""}
                >
                  <PublicationIcon></PublicationIcon>
                  <p>PUBLICATIONS</p>
                </div>
                <div
                  onClick={() => SelectPhotoTabs(2)}
                  className={selectPhotoTab === 2 ? "active" : ""}
                >
                  <ReelsIcon></ReelsIcon>
                  <p>REELS</p>
                </div>
                <div
                  onClick={() => SelectPhotoTabs(3)}
                  className={selectPhotoTab === 3 ? "active" : ""}
                >
                  <AnswerIcon></AnswerIcon>
                  <p>ACTUAL</p>
                </div>
              </div>
              <div className="content">
                {selectPhotoTab === 1 && (
                  <div className="publications comments-content">
                    <img src={warning} alt="img"></img>
                    <h1>You have not posted</h1>
                    <span>This is where your posts will appear.</span>
                  </div>
                )}
                {selectPhotoTab === 2 && (
                  <div className="reels comments-content">
                    <img src={warning} alt="img"></img>
                    <h1>You don't have any videos of Reels yet</h1>
                    <span>
                      This is where your Reels videos will be displayed.
                    </span>
                  </div>
                )}
                {selectPhotoTab === 3 && (
                  <div className="actual comments-content">
                    <img src={warning} alt="img"></img>
                    <h1>You have not created any relevant publications yet</h1>
                    <span>
                      Once created, your current posts will be shown here.
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionsPage;
