import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProductDetailsSettingsModal.scss";
import { ReportProduct, DeleteBoard } from "../../../api";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../contexts/AuthContext";

interface Props {
  setOpenSettingsModal: (openSettingsModal: boolean) => void;
  productId?: string;
}

const ProductDetailsSettingsModal = ({
  setOpenSettingsModal,
  productId,
}: Props) => {
  const navigate = useNavigate();
  const { currentUser, isLoggedIn } = useAuthContext();

  const [confirm, setConfirm] = useState<string>("Report Item");
  const [confirm_saved, setConfirm_saved] = useState<string>(
    "If Saved, Remove it"
  );

  const ReportProd = async () => {
    if (confirm === "Report Item") {
      setConfirm("Confirm Report");
    } else if (confirm === "Confirm Report") {
      let data = {
        prod_id: productId,
        report_reason: "",
      };
      console.log(data);
      await ReportProduct(data)
        .then((r) => {
          console.log(r, "successfully reported product");
          setConfirm("Report Done");
        })
        .catch((er) => {
          console.log(er, "failed to report the product");
          setConfirm("Report Done");
        });
    }
  };

  const RemoveBoard = async () => {
    if (confirm_saved === "If Saved, Remove it") {
      setConfirm_saved("Confirm Remove");
    } else if (confirm_saved === "Confirm Remove") {
      await DeleteBoard(productId)
        .then((r) => {
          console.log(r, "successfully unsaved product");
          setConfirm_saved("Removed from Board");
        })
        .catch((er) => {
          console.log(er, "failed to unsave product");
          setConfirm_saved("Uh oh, something happened");
        });
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
  }, []);

  return (
    <div className="modal settings">
      <div className="modal-content">
        <div className="settings-modal">
          <div className="general-row">
            <p>Complain</p>
          </div>
          <div className="general-row">
            <p>Cancel Subscription</p>
          </div>
          <div
            className={confirm_saved === "Confirm Remove" ? "general-row" : ""}
            onClick={RemoveBoard}
          >
            <p>{confirm_saved}</p>
          </div>
          <div>
            <p>Share...</p>
          </div>
          <div>
            <p>Copy link</p>
          </div>
          <div>
            <p>Embed on site</p>
          </div>
          <div
            className={confirm === "Confirm Report" ? "general-row" : ""}
            onClick={ReportProd}
          >
            <p>{confirm}</p>
          </div>
          <div onClick={() => setOpenSettingsModal(false)}>
            <p>Cancel</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsSettingsModal;
