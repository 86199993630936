import React, {useState} from "react";
import {ShareIcon} from "../icons/ShareIcon";
import "./ShareIconM.scss";

function copyDivToClipboard(text: any) {
  var dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}

const ShareIconM = ({product}: any) => {
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [copy, setCopy] = useState<String>("Copy");

  return (
    <div className="share-icon">
      <div
        className="share-icon-child"
        onClick={() => {
          setOpenShareModal(!openShareModal);
        }}>
        <ShareIcon />
      </div>
      <div className={`${openShareModal ? "share-open" : "share-close"}`}>
        <div className="share-link" id="copyMe">
          https://oblast.io/p/{product}
        </div>
        <div
          onClick={() => {
            copyDivToClipboard(`https://oblast.io/p/${product}`);
            setCopy("Copied...");
            setTimeout(() => {
              setCopy("Copy");
            }, 5000);
          }}>
          {copy}
        </div>
        <div
          className="share-close-pop"
          onClick={() => {
            setOpenShareModal(!openShareModal);
          }}>
          <div>Close</div>
        </div>
      </div>
    </div>
  );
};

export default ShareIconM;
