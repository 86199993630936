import React, { useEffect, useState } from "react";
import "./TimelineCard.scss";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import moment from "moment";
import { HeartIcon } from "../../components/icons/HeartIcon";
import { SavedIcon } from "../../components/icons/SavedIcon";
import { CommentIcon } from "../../components/icons/CommentsIcon";
import { ShareIcon } from "../../components/icons/ShareIcon";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  PostComment,
  AddBoard,
  DisplayBoards,
  DisplayUserInfo,
  LikeDislike,
} from "../../api";
import { ThreeDotsIcon } from "../icons/ThreeDotsIcon";
import { useDataContext } from "../../contexts/DataContext";
import { LikedIcon } from "../icons/LikedIcon";
import "../../assets/scss/Animations.scss";
import { SmileIcon } from "../icons/SmileIcon";
import Emoji from "../../components/emoji/Emoji";
import MiniUserModal from "../modals/MiniUserModal/MiniUserModal";
import { error } from "console";
import { useNavigate } from "react-router-dom";
import ShareIconM from "../Share/ShareIconM";

interface Props {
  index: number;
  products: any;
  product: any;
  setProducts: (product: any) => any;
  openModal: boolean;
  setOpenModal: (openModal: boolean) => any;
  openSettingsModal: boolean;
  setOpenSettingsModal: (openSettingsModal: boolean) => any;
  setActiveProductId: (activeProductId: string) => any;
  users: any;
}

const TimelineCard = ({
  index,
  setProducts,
  products,
  product,
  setOpenModal,
  setActiveProductId,
  setOpenSettingsModal,
  users,
}: Props) => {
  const [commentText, setCommentText] = useState<string>("");
  const [productComments, setProductComments] = useState<any[]>([]);
  const { currentUser, isLoggedIn } = useAuthContext();
  // const [count, setCount] = useState<number>(0);
  // const {favorites, setFavorites} = useDataContext();
  // const [usersId, setUsersId] = useState<[]>([]);
  // const [userss, setUserss] = useState<[]>([]);
  const [openEmojiDropdown, setOpenEmojiDropdown] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSettingsHover = () => {
    setOpenMiniUserModal(true);
  };

  const handleSettingsHoverOff = () => {
    setOpenMiniUserModal(false);
  };
  const handleWindowMouseMove = (event: any) => {
    setCoords({
      x: event.clientX,
      y: event.clientY,
    });
  };
  window.addEventListener("mousemove", handleWindowMouseMove);
  const [openMiniUserModal, setOpenMiniUserModal] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const [coords, setCoords] = useState<any>({ x: 0, y: 0 });
  const [lastCoords, setLastCoords] = useState<any>({ x: 0, y: 0 });

  const handleAddComment = async (prod: any) => {
    console.log(prod, "prodprodprodprodpordpodr");
    if (commentText.length > 2) {
      if (prod.comment_count > 0) {
        prod.comment_count += 1;
      } else {
        prod.comment_count = 1;
        console.log(prod.comment_count, "prod.comment_count");
      }
      const commentBody = {
        userid_of_commentor: currentUser?.user_ID,
        product_id: prod._id,
        comment_username: currentUser?.username,
        comment: commentText,
        date: Date.now().toString(),
        prod_uploader_id: prod.uploaderId,
      };
      setProductComments(prod?.comments);
      await PostComment(commentBody)
        .then(() => {
          setCommentText("");
          setProductComments([...productComments, commentBody]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("Characters length should be greater than 2");
    }
  };

  const handleCommentClick = (index: string) => {
    setOpenModal(true);
    setActiveProductId(index);
  };

  const handleSettingsClick = (index: string) => {
    setOpenSettingsModal(true);
    setActiveProductId(index);
  };

  const handleSaved = async () => {
    await AddBoard(product._id)
      .then((res) => {
        console.log(res, "res");
      })
      .catch((er) => {
        console.log(er, "error adding to board");
      });
  };

  const likeDislike = (value: any) => {
    if (isLoggedIn === false) {
      navigate("/");
      return;
    }
    const userid = currentUser.user_ID;
    if (value === "like") {
      const uploader_id = product.uploaderId;
      const prod_id = product._id;
      LikeDislike({ userid, uploader_id, prod_id })
        .then(() => {
          //show success message
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toggleHeart = (value: any) => {
    if (liked === false) {
      likeDislike("like");
    }
    setLiked(!liked);
  };

  const toggleSaved = (value: any) => {
    setSaved(!saved);
    handleSaved();
  };

  return (
    <div className="timeline-card" key={product._id}>
      <div className="timeline-card-header">
        <div onClick={() => navigate(`/u/${product?.username}`)}>
          <div className="timeline-card-header-avatar">
            <img src={product?.profile_picture || defaultAvatar} alt="avatar" />
          </div>
          <div
            className="user-info"
            onMouseLeave={() => {
              handleSettingsHoverOff();
            }}
          >
            <h4
              className="user-name"
              onMouseEnter={(e) => {
                setUser(e);
                handleSettingsHover();
                setLastCoords({ x: 0, y: 0 });
              }}
              onMouseLeave={() => {
                setLastCoords({ ...coords });
              }}
            >
              {product?.username?.length
                ? product.username.length > 20
                  ? `${product.username.substring(0, 20)}`
                  : product.username
                : "No Username"}
            </h4>
            <p className="date">
              •{" "}
              {!product.Date
                ? "15h"
                : moment(product.Date).format("ddd MMM D | h:mm A")}
            </p>
          </div>
        </div>
        <div
          className="three-dots"
          onClick={() => handleSettingsClick(product._id)}
        >
          <ThreeDotsIcon />
        </div>
      </div>
      <div className="timeline-card-body">
        <div className="product-image">
          <img src={product?.Images || defaultAvatar} alt="product" />
        </div>
        <div className="product-actions">
          <div className="left-side-action">
            {/* <div className="like">
              <span onClick={() => likeDislike("like")}>Like it?</span>
            </div> */}
            <div
              className={`heart ${liked ? "liked" : ""}`}
              onClick={toggleHeart}
            ></div>
            <div
              className="comment"
              onClick={() => handleCommentClick(product._id)}
            >
              <CommentIcon />
            </div>
            <ShareIconM product={product._id} />
          </div>
          <div
            className={`save-post ${saved ? "saved-pulse" : ""}`}
            onClick={toggleSaved}
          >
            <SavedIcon />
          </div>
        </div>
        <div className="product-desc">
          <p className="desc">
            <b
              className="user-name"
              onClick={() => navigate(`/u/${product?.username}`)}
            >
              {product?.username?.length
                ? product.username.length > 16
                  ? `${product.username.substring(0, 16)}`
                  : product.username
                : "No Username"}
            </b>
            <span onClick={() => handleCommentClick(product._id)}>
              {product?.Title}
            </span>
          </p>
        </div>
        <div className="product-comments">
          {product?.comment_count > 0 ? (
            <div
              className="view-comments"
              onClick={() => handleCommentClick(product._id)}
            >
              <p>View all {product.comment_count} comments</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="add-comment">
          <input
            type="text"
            placeholder="Write your comment here..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <div
            className="add_comment_icon"
            onClick={() => handleAddComment(product)}
          >
            Send
          </div>
          <div
            className="emoji-icon"
            onClick={() => {
              setOpenEmojiDropdown(!openEmojiDropdown);
            }}
          >
            <SmileIcon />
            {openEmojiDropdown && (
              <div className="emoji-section">
                <Emoji
                  height="100%"
                  width="100%"
                  addEmoji={(emoji: any) => {
                    setCommentText(commentText + emoji);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {openMiniUserModal && (
        <MiniUserModal
          user={users}
          setOpenMiniUserModal={setOpenMiniUserModal}
          coords={coords}
          lastCoords={lastCoords}
        />
      )} */}
    </div>
  );
};

export default TimelineCard;
