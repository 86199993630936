import React, {useEffect, useState} from "react";
import {SearchUsername, SearchTimeline} from "../../api";
import "./SearchPage.scss";
import {useNavigate} from "react-router-dom";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import {PulseLoader} from "react-spinners";
import ProductDetailsModal from "../../components/modals/ProductDetailsModal/ProductDetailsModal";
import ProductDetailsSettingsModal from "../../components/modals/ProductDetailsSettingsModal/ProductDetailsSettingsModal";

const SearchPage = () => {
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [users, setUsers] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [searchType, setSearchType] = useState<string>("Users");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const handleCommentClick = (index: string) => {
    setOpenModal(true);
    setActiveProductId(index);
  };

  const search = (value: any, page: number) => {
    console.log(page, "searching for page");
    if (value && searchType === "Users") {
      setIsLoading(true);
      SearchUsername(value, page * 10)
        .then((response) => {
          setIsLoading(false);
          if (typeof response.data === typeof "") {
          } else {
            // console.log(response.data, "return of the request");
            let myArray = users;
            // console.log(myArray, "list of users before merge");
            myArray.push(...response.data);
            // console.log(myArray, "list of users after merge");
            setUsers(myArray);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } else if (value && searchType === "Products") {
      setIsLoading(true);
      SearchTimeline(value.replace(" ", "+"), page * 10)
        .then((response) => {
          setIsLoading(false);
          if (typeof response.data === typeof "") {
          } else {
            // console.log(response.data, "return of the request");
            let myArray = products;
            // console.log(myArray, "list of products before merge");
            myArray.push(...response.data);
            // console.log(myArray, "list of products after merge");
            setProducts(myArray);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    }
  };

  const search_with_page = (skip: number) => {
    search(query, skip);
  };

  // const previous = () => {
  //   if (page <= 1) {
  //     return;
  //   } else {
  //     const ppage = page - 1;
  //     setPage(ppage);
  //     search_with_page(ppage);
  //   }
  // };

  // const next = () => {
  //   const npage = page + 1;
  //   setPage(npage);
  //   search_with_page(npage);
  // };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setUsers([]);
      setProducts([]);
      setPage(0);
      search(query, 0);
      console.log("searching and thus clearing products and users");
    }, 1500);
    return () => clearTimeout(timeOutId);
  }, [query]);

  const handleScroll = (e: any) => {
    const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
    if (bottom) {
      console.log("reached the bottom scroll");
      const npage = page + 1;
      setPage(npage);
      search_with_page(npage);
    }
  };

  return (
    <div id="general-page" className="search-page">
      <div className="search-section">
        <h1>Searching for {searchType}</h1>
        <div className="tab-section">
          <button
            className={`${searchType === "Users" ? "active search-user-button" : null}`}
            onClick={() => {
              // setUsers([]);
              // setProducts([]);
              setSearchType("Users");
            }}>
            Users
          </button>
          <button
            className={`${searchType === "Products" ? "active search-products-button" : null}`}
            onClick={() => {
              // setUsers([]);
              // setProducts([]);
              setSearchType("Products");
            }}>
            Products
          </button>
        </div>
        <div className="input-section">
          <input
            placeholder={`${searchType === "Products" ? "Search Products" : "Search Users"}`}
            type="text"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
        </div>
      </div>
      {searchType === "Users" && (
        <div className={`${!isLoading && users.length > 0 ? "users-results-section" : "no-users-results"}`} onScroll={handleScroll}>
          {!isLoading ? (
            <div>
              {users?.length > 0 ? (
                <div>
                  {users?.map((item: any, idx: any) => {
                    return (
                      <div key={idx} className="history-users" onClick={() => navigate(`/u/${item.username}`)}>
                        <div className="avatar_section">{item.image ? <img src={item.image} alt="img" /> : <img src={defaultAvatar} alt="avatar" />}</div>
                        <div className="info_section">
                          <p>{item.username}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="nothing-found">{query.length > 0 ? <p>Couldn't find any users, try searching again</p> : <p>Search for a user.</p>}</div>
              )}
            </div>
          ) : (
            <div className="spinner">
              <PulseLoader color="#0095f6" />
            </div>
          )}
        </div>
      )}
      {searchType === "Products" && (
        <div className={`${!isLoading && products.length > 0 ? "products-results-section" : "no-products-results"}`} onScroll={handleScroll}>
          {isLoading ? (
            <div className="spinner">
              <PulseLoader color="#0095f6" />
            </div>
          ) : (
            <>
              {products?.length > 0 ? (
                <>
                  {products?.map((item: any, idx: any) => {
                    return (
                      <div key={idx} className="history-products" onClick={() => handleCommentClick(item._id)}>
                        <div className="product-image">
                          {item.Images ? (
                            <img className="decent-size-image-search" src={item.Images} alt="img" />
                          ) : (
                            <img className="decent-size-image-search" src={defaultAvatar} alt="avatar" />
                          )}
                        </div>
                        <div className="info-section">
                          <p onClick={() => navigate(`/u/${item.username}`)}>{item.username}</p>
                          <p>{item.Title.substring(0, 60) + "..."}</p>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="nothing-found">{query.length > 0 ? <p>Couldn't find any products, try searching again</p> : <p>Search for a product.</p>}</div>
              )}
            </>
          )}
        </div>
      )}
      {/* <div className="pagination">
        <div
          className="prev"
          onClick={() => {
            // setUsers([]);
            // setProducts([]);
            // previous();
          }}>
          <ArrowRightIcon />
          <span>Previous</span>
        </div>
        <div className="page-number">{page}</div>
        <div
          className="next"
          onClick={() => {
            // setUsers([]);
            // setProducts([]);
            // next();
          }}>
          <span>Next</span>
          <ArrowRightIcon />
        </div>
      </div> */}
      {openModal && (
        <ProductDetailsModal
          productId={activeProductId}
          setOpenModal={setOpenModal}
          setOpenSettingsModal={setOpenSettingsModal}
          openSettingsModal={openSettingsModal}
        />
      )}
      {openSettingsModal && <ProductDetailsSettingsModal setOpenSettingsModal={setOpenSettingsModal} productId={activeProductId} />}
    </div>
  );
};

export default SearchPage;
