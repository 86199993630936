import React from "react";
import "./ForgetPasswordPage.scss";
import lightLogo from "../../assets/images/logolight.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CheckEmailForgotPass } from "../../api";

const ForgetPasswordPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const recoverPassword = (data: any) => {
    CheckEmailForgotPass(data)
      .then((response) => {
        if (response.data) {
          // alert(response.data);
          console.log("please check your spam folder");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/passwordrecovery2", { state: { data } });
    reset();
  };

  return (
    <div className="forget-page">
      <div className="forget-page-section">
        <div className="forget-page-section-form">
          <div className="forget-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
            <h3>Password Recovery</h3>
          </div>
          <div className="forget-page-section-form-description">
            <p>Please enter your email associated to your account:</p>
          </div>
          <form
            onSubmit={handleSubmit(recoverPassword)}
            className="content_section"
          >
            <label className="label">
              <input
                {...register("email_or_phone", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Please enter a valid email",
                  },
                })}
                placeholder="Email address"
                type="email"
                className="input"
              />
            </label>
            <button className="send_btn" type="submit">
              Send Email
            </button>
          </form>
          <div className="forget-page-section-form-description">
            <p>
              We will send a link for creating a new password to your email
              address.
            </p>
            <p>
              Note: If you can’t see the email, please make sure you checked the
              “Spam” folder as well.
            </p>
          </div>
          <p>
            Need help? <b> Contact Us</b>
          </p>
        </div>
        <div className="forget-page-section-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate("/")}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
