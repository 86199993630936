import './App.scss';
import { AuthContextProvider } from './contexts/AuthContext';
import { DataContextProvider } from './contexts/DataContext';
import { ProductContextProvider } from './contexts/ProductContext';
import Router from './router/Router';

function App() {
  return (
    <>
    <AuthContextProvider>
      <DataContextProvider>
        <ProductContextProvider>
          <Router />  
        </ProductContextProvider>
      </DataContextProvider>
    </AuthContextProvider>
  </>
  );
}

export default App;
