import React, { useState, useEffect } from "react";
import "./AddProductPage.scss";
import defaultImg from "../../assets/images/defaultimg.png";
import { AddProduct, UploadProduct } from "../../api";
import { useAuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export const AddProductPage = () => {
  const { currentUser, isLoggedIn } = useAuthContext();
  const [image, setImage] = useState<any>(null);
  const [imageURL, setImageURL] = useState<any>("");
  const [savedInfo, setSavedInfo] = useState<string>("");
  const [productDescription, setProductDescription] = useState<string>("");
  const [productUrl, setProductUrl] = useState<string>("");
  const [productTitle, setProductTitle] = useState<string>("");
  const [canPost, setCanPost] = useState<boolean>(false);
  const navigate = useNavigate();

  const getImage = (file: any) => {
    setCanPost(false);
    if (file.length) {
      setImage(file[0]);
      const formData = new FormData();
      setSavedInfo("uploading, please wait...");
      formData.append("file", file[0]);
      UploadProduct(formData)
        .then((res) => {
          if (res.status === 200) {
            setImageURL(res.data);
            setSavedInfo("uploaded! You can save now");
            setCanPost(true);
          } else {
            setSavedInfo("error while uploading picture");
          }
        })
        .catch((er) => {
          setSavedInfo(`${er} error while uploading picture`);
        });
    }
  };
  const productTitleChange = (event: any) => {
    setProductTitle(event.target.value);
  };
  const productUrlChange = (event: any) => {
    setProductUrl(event.target.value);
  };
  const productDescriptionChange = (event: any) => {
    setProductDescription(event.target.value);
  };

  const createProduct = async () => {
    let data = {
      uploaderId: currentUser.user_ID,
      Title: productTitle,
      Description: productDescription,
      Images: imageURL,
      Date: new Date(),
      Url: productUrl,
    };
    if (canPost) {
      setSavedInfo("Updating Information...");
      await AddProduct(data)
        .then((res) => {
          setSavedInfo("Saved Info!");
          setProductTitle("");
          setProductDescription("");
          setProductUrl("");
          setImageURL("");
          setImage("");

          //redirect them to the profile page
          navigate("/profile-page");
        })
        .catch((er) => {
          setSavedInfo(`${er} error while updating`);
        });
    } else {
      setSavedInfo("picture isn't ready, can't post yet");
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
  }, []);
  return (
    <div className="addproduct-page">
      <div className="product-container">
        <div
          className="avatar"
          //   onClick={(e) => {
          //     getImage(e.target.files);
          //   }}
        >
          {image ? (
            <img src={URL.createObjectURL(image)} alt="img"></img>
          ) : (
            <img src={imageURL || defaultImg} alt="uset-avatar" />
          )}
        </div>
        <div>
          <label className="upload-file">
            [add product image]
            <input
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                getImage(e.target.files);
              }}
            />
          </label>
          <p className="save-info">{savedInfo}</p>
        </div>
        <div>
          <p>Title</p>
          <input
            value={productTitle}
            placeholder="Product Title"
            onChange={productTitleChange}
          />
        </div>
        <div>
          <p>Description</p>
          <textarea
            value={productDescription}
            placeholder="Product Description"
            onChange={productDescriptionChange}
          />
        </div>
        <div>
          <p>Url</p>
          <input
            value={productUrl}
            placeholder="Product Url"
            onChange={productUrlChange}
          />
        </div>
        <div className="button_section">
          <button onClick={createProduct}>add</button>
        </div>
      </div>
    </div>
  );
};
