import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CheckJWTToken,
  DisplayUserInfo,
  LogOut,
  SearchUsername,
} from "../../api";
import { useAuthContext } from "../../contexts/AuthContext";
import "./Sidebar.scss";
import websiteLogo from "../../assets/images/logolight.png";
import { SearchIcon } from "../icons/SearchIcon";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import { SavedIcon } from "../icons/SavedIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { HamburgerIcon } from "../icons/HamburgerIcon";
import { ArrowRightIcon } from "../icons/ArrowRightIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { ActionsIcon } from "../icons/ActionsIcon";
import { SwitchThemeIcon } from "../icons/SwitchThemeIcon";
import { ProblemReportIcon } from "../icons/ProblemReportIcon";
import ProblemReportModal from "../modals/ProblemReportModal/ProblemReportModal";
import { CloseIcon } from "../icons/CloseIcon";
import { AddProductIcon } from "../icons/AddProductIcon";

const Sidebar = () => {
  const oblastJwtToken = localStorage.getItem("oblast_jwt_token");
  const { currentUser, setCurrentUser, isLoggedIn, setIsLoggedIn } =
    useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState<[]>([]);
  const [text, setText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [searchHistory, setSearchHistory] = useState<any>(
    localStorage.searchHistory ? JSON.parse(localStorage.searchHistory) : []
  );
  const [showHistory, setShowHistory] = useState<any>();
  const [hideNavbar, setHideNavbar] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>("/discovery");
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [openProblemReportModal, setOpenProblemReportModal] =
    useState<boolean>(false);
  // const ref = useRef<any>(null);

  // const handleClickOutside = (event: any) => {
  //   if (!event.target.classList.contains("dropdown-menu")) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       setShowDropdown(false);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  const logOut = () => {
    LogOut()
      .then(() => {
        localStorage.removeItem("oblast_jwt_token");
        setIsLoggedIn(false);
        navigate("/");
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchUser = (value: any) => {
    if (value) {
      SearchUsername(value, skip)
        .then((response) => {
          if (typeof response.data === typeof "") {
          } else {
            setUsers(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      const search = {
        text: text,
        date: new Date().toISOString().substr(0, 19),
      };
      const cloneSearchHistory = [...searchHistory];
      cloneSearchHistory.push(search);
      setSearchHistory(cloneSearchHistory);
      localStorage.searchHistory = JSON.stringify(cloneSearchHistory);
      setText("");
    }
  };

  const clearHistory = (index: number) => {
    const filtered = searchHistory.filter(
      (el: any, idx: number) => idx !== index
    );
    setSearchHistory(filtered);
    localStorage.searchHistory = JSON.stringify(filtered);
  };

  useEffect(() => {
    const data = localStorage.searchHistory
      ? JSON.parse(localStorage.searchHistory)
      : [];
    setShowHistory(data);
  }, [searchHistory]);

  const fetchUserData = () => {
    CheckJWTToken({ token: oblastJwtToken })
      .then(async (response) => {
        const { data } = response;
        // setCurrentUser({
        //   user_ID: data.user_ID,
        //   username: data.username,
        // });
        DisplayUserInfo(data.user_ID)
          .then(async (res) => {
            const userData = {
              user_ID: data.user_ID,
              username: res?.data.username,
              image: res?.data.image,
              description: res?.data.description,
            };
            setCurrentUser(userData);
            setIsLoggedIn(true);
          })
          .catch((err) => {
            console.log("errrrr", err);
            setIsLoggedIn(false);
          });
      })
      .catch((err) => {
        console.log("errrrr", err);
        setIsLoggedIn(false);
      });
  };

  useEffect(() => {
    console.log("changing route inside useffect", location.pathname);
    switch (location.pathname) {
      case "/discovery":
        setActiveTab("discovery");
        break;
      case "/timeline":
        setActiveTab("timeline");
        break;
      case "/search":
        setActiveTab("search");
        break;
      //   case "/messages":
      //     setActiveTab("messages");
      //     break;
      case "/favorites-page":
        setActiveTab("favorites");
        break;
      case "/add":
        setActiveTab("addproduct");
        break;
      case "/profile-page":
        setActiveTab("profile");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (oblastJwtToken) {
      fetchUserData();
    }
  }, [oblastJwtToken]);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/sign-up" ||
      location.pathname === "/verifyemail" ||
      location.pathname === "/passwordrecovery" ||
      location.pathname === "/passwordrecovery2" ||
      location.pathname === "/change-password"
    ) {
      setHideNavbar(true);
      document.querySelector<any>(".main-app").classList.remove("oblast-app");
    } else {
      setHideNavbar(false);
      document.querySelector<any>(".main-app").classList.add("oblast-app");
    }
  }, [location]);

  const handleSettingsClick = () => {
    setOpenProblemReportModal(true);
  };

  useEffect(() => {
    const sidenav = document.getElementById("my-sidenav");
    if (sidenav) {
      const x = (sidenav.style.width = "0px");
    }
  }, []);

  const openNav = () => {
    const sidenav = document.getElementById("my-sidenav");
    const sidebar = document.getElementById("my-sidebar");
    if (sidenav && sidebar) {
      const x = (sidenav.style.width = "400px");
      const z = (sidenav.style.border = "1px solid rgb(219, 219, 219)");
      const y = (sidebar.style.width = "65px");
      const j = (sidenav.style.display = "block");
      sidebar.style.zIndex = "9999";
      sidebar.style.overflow = "hidden";
    }
  };

  const closeNav = () => {
    const sidenav = document.getElementById("my-sidenav");
    const sidebar = document.getElementById("my-sidebar");
    if (sidenav && sidebar) {
      const x = (sidenav.style.width = "0px");
      const y = (sidebar.style.width = "244px");
      const z = (sidenav.style.border = "none");
      sidebar.style.zIndex = "1";
    }
  };

  return (
    !hideNavbar && (
      <div className="sidebar" id="my-sidebar">
        <div className="website-logo">
          <img
            src={websiteLogo}
            alt="websiteLogo"
            onClick={() => navigate("/discovery")}
          />
          <h3 className="website-name" onClick={() => navigate("/discovery")}>
            OBLAST Discover
          </h3>
        </div>
        <div className="sidbar-nav">
          {/* <div className={`nav-item hide-discovery ${activeTab === "discovery" ? "active" : null}`} onClick={() => navigate("/discovery")}>
            <SavedIcon />
            <h3 className="nav-name">Discovery</h3>
          </div> */}
          <div
            className={`nav-item ${activeTab === "timeline" ? "active" : null}`}
            onClick={() => navigate("/timeline")}
          >
            <HomeIcon />
            <h3 className="nav-name">Timeline</h3>
          </div>
          <div
            className={`nav-item ${
              activeTab === "search" ? "active search-active" : null
            }`}
            onClick={() => navigate("/search")}
          >
            <SearchIcon />
            <h3 className="nav-name">Search</h3>
          </div>
          {/* <div
            className={`nav-item ${activeTab === "messages" ? "active" : null}`}
          >
            <MessageIcon />
            <h3 className="nav-name">Messages</h3>
          </div> */}
          <div
            className={`nav-item ${
              activeTab === "favorites" ? "active" : null
            }`}
            onClick={() => navigate("/favorites-page")}
          >
            <SavedIcon />
            <h3 className="nav-name">Saved</h3>
          </div>
          {/* <div
            className={`nav-item ${activeTab === "saved" ? "active" : null}`}
          >
            <HeartIcon />
            <h3 className="nav-name">Favorites</h3>
          </div> */}
          <div
            className={`nav-item ${
              activeTab === "addproduct" ? "active search-active" : null
            }`}
            onClick={() => navigate("/add")}
          >
            <AddProductIcon />
            <h3 className="nav-name">Add Product</h3>
          </div>
          <div
            className={`nav-item ${activeTab === "profile" ? "active" : null}`}
            onClick={() => navigate("/profile-page")}
          >
            <img src={currentUser?.image || defaultAvatar} alt="uset-avatar" />
            <h3 className="user-name">Profile</h3>
          </div>

          <div
            className="sidebar-footer dropdown-menu"
            onClick={() => {
              setShowDropdown(!showDropdown);
            }}
          >
            <HamburgerIcon />
            <p>More</p>
            {showDropdown && (
              <div className="hamburger-menu">
                <div
                  className="hamburger-menu-row"
                  onClick={() => navigate("/settings-page")}
                >
                  <div>
                    <SettingsIcon />
                    <p>Settings</p>
                  </div>
                  <div className="arrow-icon">
                    <ArrowRightIcon />
                  </div>
                </div>
                <div className="hamburger-menu-row" onClick={logOut}>
                  <div>
                    <p>Log out</p>
                  </div>
                  <div className="arrow-icon">
                    <ArrowRightIcon />
                  </div>
                </div>
                <div
                  className="hamburger-menu-row"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  <div>
                    <p>Cancel</p>
                  </div>
                  <div className="arrow-icon">
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {openProblemReportModal && (
          <ProblemReportModal
            setOpenProblemReportModal={setOpenProblemReportModal}
          />
        )}
      </div>
    )
  );
};

export default Sidebar;
