import React, { useEffect, useState } from "react";
import "./ProductDetailsModalSelf.scss";
import {
  GetComments,
  PostComment,
  ShowSingleProduct,
  UpdateProduct,
  DeleteCommentsOnMyProducts,
} from "../../../api";
import { CloseIcon } from "../../icons/CloseIcon";
import { ThreeDotsIcon } from "../../icons/ThreeDotsIcon";
import { SavedIcon } from "../../icons/SavedIcon";
import { SmileIcon } from "../../icons/SmileIcon";
import { useAuthContext } from "../../../contexts/AuthContext";
import Emoji from "../../emoji/Emoji";
import { useNavigate } from "react-router-dom";
import ProductDetailsSettingsModalSelf from "../ProductDetailsSettingsModalSelf/ProductDetailsSettingsModalSelf";
import ShareIconM from "../../Share/ShareIconM";
import OwnProfileComments from "../../comments/OwnProfileComments";

interface Props {
  productId?: string;
  setOpenModal: (openModal: boolean) => void;
  openSettingsModal: boolean;
  setOpenSettingsModal: (openSettingsModal: boolean) => any;
  removeDeleteProduct: any;
}

const ProductDetailsModalSelf = ({
  productId,
  setOpenModal,
  openSettingsModal,
  setOpenSettingsModal,
  removeDeleteProduct,
}: Props) => {
  const { currentUser } = useAuthContext();
  const [product, setProduct] = useState<any>(null);
  const [commentValue, setCommentValue] = useState<string>("");
  const [comments, setComments] = useState<any[]>([]);
  const [commentConfig, setCommentConfig] = useState<any>({
    skip: 0,
    length: 0,
  });
  const [selectEmoji, setSelectEmoji] = useState<boolean>(false);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [prodDescription, setProdDescription] = useState<string>("");
  const [prodUrl, setProdUrl] = useState<string>("");
  const [prodTitle, setProdTitle] = useState<string>("");
  const [prodTitle1, setProdTitle1] = useState<string>("");
  const [prodDescription1, setProdDescription1] = useState<string>("");
  const [prodUrl1, setProdUrl1] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    ShowSingleProduct(productId)
      .then((response) => {
        if (response.status === 200) {
          setProduct(response.data);
          setProdDescription(response.data?.Description);
          setProdUrl(response.data?.Url);
          setProdTitle(response.data?.Title);
          setProdTitle1(response.data?.Title);
          setProdDescription1(response.data?.Description);
          setProdUrl1(response.data?.Url);
          GetComments(response.data._id)
            .then((res) => {
              setComments(res.data);
              setCommentConfig({
                skip: commentConfig?.skip + 20,
                length: commentConfig?.length - 20,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("SOMETHING WENT WRONG!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const postComments: any = () => {
    if (commentValue.length > 2) {
      let data = {
        userid_of_commentor: currentUser.user_ID,
        product_id: productId,
        comment_username: currentUser.username,
        comment: commentValue,
        date: new Date(),
        prod_uploader_id: product.uploaderId,
      };
      PostComment(data)
        .then(() => {
          setCommentValue("");
          GetComments(productId)
            .then((res: any) => {
              setComments(res.data);
              setCommentConfig({
                skip: commentConfig?.skip + 20,
                length: commentConfig?.length - 20,
              });
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      alert("Characters length should be greater than 2");
    }
  };

  const handleSettingsModalClick = () => {
    setOpenSettingsModal(true);
  };

  const prod_description_change = (event: any) => {
    setProdDescription(event.target.value);
  };
  const prod_url_change = (event: any) => {
    setProdUrl(event.target.value);
  };
  const prod_title_change = (event: any) => {
    setProdTitle(event.target.value);
  };

  const updateProd = async (data: any) => {
    await UpdateProduct(data)
      .then((r) => {
        setProdTitle1(data.Title);
        setProdDescription1(data.Description);
        setProdUrl1(data.Url);
      })
      .catch((er) => {
        console.log(er, "failed to update item");
      });
  };

  const removeProdDelete = () => {
    removeDeleteProduct(productId);
  };

  const onCommentDelete = () => {
    //refresh here the comments
    GetComments(productId)
      .then((res: any) => {
        setComments(res.data);
        setCommentConfig({
          skip: commentConfig?.skip + 20,
          length: commentConfig?.length - 20,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  return (
    <div className="modal">
      <div className="close-modal" onClick={() => setOpenModal(false)}>
        <CloseIcon />
      </div>
      <div className="modal-content">
        <div className="product-details-modal-self">
          <div className="product-image">
            <img src={product?.Images} alt="img" />
          </div>
          <div className="product-comments-section">
            <div className="comments-body">
              <div className="comments-header">
                <div className="comments-header-username">
                  <img
                    onClick={() => navigate(`/u/${product?.username}`)}
                    src={product?.profile_picture}
                    alt="avatar"
                  />
                  <h4
                    className="user-name"
                    onClick={() =>
                      navigate(`/friends-pro file/${product?.username}`)
                    }
                  >
                    {product?.username}
                  </h4>
                </div>
                <div
                  className="comments-header-icon"
                  onClick={() => handleSettingsModalClick()}
                >
                  <ThreeDotsIcon />
                </div>
              </div>
              {prodDescription1 && (
                <div className="comments-description-section">
                  <div>
                    <span className="title">
                      {prodTitle1.length > 0 ? (
                        <>
                          {prodTitle1.length > 80
                            ? prodTitle1.substring(0, 80) + "..."
                            : prodTitle1}
                        </>
                      ) : (
                        <span>Title</span>
                      )}
                    </span>
                    <span className="description">
                      {prodDescription1.substring(0, 800) + "..."}
                    </span>
                    {product?.Url && (
                      <button
                        onClick={() =>
                          window.open(prodUrl1, "_blank", "noreferrer")
                        }
                      >
                        [View Product Source]
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div>
                <div className="item-builder">
                  <div className={`${openEditor ? "" : "hider"}`}>
                    <div className="item-update-areas">
                      <div>
                        <p>Title</p>
                        <input
                          value={prodTitle}
                          placeholder="Item Title"
                          onChange={prod_title_change}
                        />
                      </div>
                      <div>
                        <p>Description</p>
                        <textarea
                          value={prodDescription}
                          placeholder="Item Description"
                          onChange={prod_description_change}
                        />
                      </div>
                      <div>
                        <p>Url</p>
                        <input
                          value={prodUrl}
                          placeholder="Item Url"
                          onChange={prod_url_change}
                        />
                      </div>
                      <div className="button_section">
                        <button
                          onClick={() => {
                            let data = {
                              _id: product?._id,
                              uploaderId: product?.uploaderId,
                              Title: prodTitle,
                              Description: prodDescription,
                              Url: prodUrl,
                              Images: product?.Images,
                            };
                            updateProd(data);
                          }}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="show-item-update">
                    <button onClick={() => setOpenEditor(!openEditor)}>{`${
                      openEditor ? "Hide" : "Show"
                    } item update`}</button>
                  </div>
                </div>
              </div>
              <div className="user-comment">
                {comments.map((comment) => {
                  return (
                    <OwnProfileComments
                      onDeleteRefresh={onCommentDelete}
                      comment_id={comment.id}
                      comment_username={comment?.comment_username}
                      comment_text={comment.comment}
                    />
                  );
                })}
              </div>
            </div>
            <div className="comments-footer">
              <div className="icons-section">
                <div className="heart-comment-share">
                  <ShareIconM product={productId} />
                </div>
                <div className="save-icon">
                  <div>
                    <SavedIcon />
                  </div>
                </div>
              </div>
              <div className="add-comment-left">
                <div
                  onClick={() => {
                    setSelectEmoji(!selectEmoji);
                  }}
                  className="emoji-open-icon"
                >
                  <SmileIcon />
                  {selectEmoji && (
                    <div className="emoji-section">
                      <Emoji
                        addEmoji={(emoji: any) => {
                          setCommentValue(commentValue + emoji);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <textarea
                    placeholder="Add a comment..."
                    value={commentValue}
                    onChange={(e) => setCommentValue(e.target.value)}
                  />
                </div>
                <div className="circle-button">
                  <div></div>
                  <button onClick={postComments}>[Post]</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSettingsModal ? (
        <ProductDetailsSettingsModalSelf
          setOpenSettingsModal={setOpenSettingsModal}
          productId={productId}
          confirmDelete={removeProdDelete}
        />
      ) : null}
    </div>
  );
};

export default ProductDetailsModalSelf;
