import React from "react";
import { useNavigate } from "react-router-dom";
import "../ProductDetailsSettingsModal/ProductDetailsSettingsModal.scss";
import "../../../assets/scss/Animations.scss";
import { DeleteProduct } from "../../../api";
import { useState } from "react";
interface Props {
  setOpenSettingsModal: (openSettingsModal: boolean) => void;
  productId?: string;
  confirmDelete: any;
}

const ProductDetailsSettingsModalSelf = ({
  setOpenSettingsModal,
  productId,
  confirmDelete,
}: Props) => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState<string>("Delete Item");

  // console.log(productId, "product id at loading");

  const DeleteProd = async () => {
    if (confirm === "Delete Item") {
      setConfirm("Confirm Delete");
    } else if (confirm === "Confirm Delete") {
      // console.log(productId, "product id at deleting");
      await DeleteProduct(productId)
        .then((r) => {
          console.log(r, "successfully deleted product");
          setConfirm("Successfully Deleted");
          confirmDelete();
        })
        .catch((er) => {
          console.log(er, "failed to delete the product");
        });
    } else if (confirm === "Successfully Deleted") {
      setConfirm("Item Already Deleted");
    }
  };

  return (
    <div className="modal settings">
      <div className="modal-content">
        <div className="settings-modal">
          <div
            className={
              confirm === "Confirm Delete" ? "general-row fill-delete" : ""
            }
            onClick={DeleteProd}
          >
            <p>{confirm}</p>
          </div>
          <div onClick={() => setOpenSettingsModal(false)}>
            <p>Close</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsSettingsModalSelf;
