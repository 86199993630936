import React, { useEffect, useState } from "react";
import "./NewMessageModal.scss";
import { CloseIcon } from "../../icons/CloseIcon";
import defaultAvatar from "../../../assets/images/default-avatar.jpg";
import { SearchUsername } from "../../../api";

interface Props {
  setOpenNewMessageModal: (openNewMessageModal: boolean) => void;
  usersId: any;
  setUsersId: (arr: any) => void;
}
const NewMessageModal = ({
  setOpenNewMessageModal,
  usersId,
  setUsersId,
}: Props) => {
  const [users, setUsers] = useState<any>([]);
  const [text, setText] = useState<string>("");
  const [skip, setSkip] = useState<number>(0);
  const [tempUsersIds, setTempUsersIds] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (text.length > 0) {
          const res = await SearchUsername(text, skip);
          if (typeof res.data !== "string") {
            return setUsers(res.data);
          }
          setUsers([]);
        }
      } catch (error) {
        console.log(error);
        alert(error);
      }
    };

    fetchData();
  }, [text]);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-block">
          <div className="modal-header">
            <h4>A new message</h4>
          </div>
          <div className="modal-search">
            <div className="modal_added">
              {tempUsersIds?.map((elm: any) => {
                return (
                  <div className="user_added">
                    <span>{elm.name} </span>
                    <button
                      onClick={() => {
                        setTempUsersIds(
                          tempUsersIds.filter(
                            (item: any) => item.uid !== elm.uid
                          )
                        );
                      }}
                    >
                      &times;
                    </button>
                  </div>
                );
              })}
            </div>
            <label>To whom:</label>
            <input
              type="text"
              placeholder="Search..."
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </div>
          <div className="users-section">
            {users?.length < 1 || !users?.length ? (
              <div className="not-found">
                <span>Accounts not found</span>
              </div>
            ) : (
              users?.map((e: any) => {
                return (
                  <div key={e?._id} className="board_users">
                    <div className="avatar_section">
                      {e?.image ? (
                        <div>
                          <img src={e?.image} alt="img" />
                        </div>
                      ) : (
                        <div>
                          <img src={defaultAvatar} alt="avatar" />
                        </div>
                      )}
                    </div>
                    <div className="info_section">
                      <p>{e?.username}</p>
                    </div>
                    <div className="chackbox-div">
                      <label className="container">
                        <input
                          type="checkbox"
                          onChange={(elm) => {
                            if (elm.target.checked === true) {
                              setTempUsersIds([
                                ...tempUsersIds,
                                {
                                  uid: e?._id,
                                  name: e?.username,
                                  image: e?.image,
                                },
                              ]);
                              setText("");
                            } else {
                              setTempUsersIds(
                                tempUsersIds.filter(
                                  (item: any) => item.uid !== e._id
                                )
                              );
                            }
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="button-section">
            <button
              onClick={() => {
                setOpenNewMessageModal(false);
                setUsersId([...usersId, tempUsersIds]);
                setTempUsersIds([]);
              }}
            >
              Chat
            </button>
          </div>
          <div onClick={() => setOpenNewMessageModal(false)}>
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessageModal;
