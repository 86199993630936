import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./LoginPage.scss";
import { useForm } from "react-hook-form";
import { SignIn } from "../../api";
import lightLogo from "../../assets/images/logolight.png";

const LoginPage = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);
  const [message, setMessage] = useState<String>("");
  const [log_spinner, setLog_spinner] = useState<any>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const login = (value: any) => {
    setMessage("");
    setLog_spinner(<div className="loader"></div>);
    SignIn(value)
      .then((response: any) => {
        const { data } = response;
        if (data) {
          localStorage.setItem("oblast_jwt_token", data.oblastjwttoken);
          setLog_spinner("");
          navigate("/discovery");
          reset();
        }
      })
      .catch((error: any) => {
        setLog_spinner("");
        setMessage("Login Failed...");
        console.log(error);
      });
  };

  const handleCheckbox = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked === true) {
      // localStorage.setItem("checked", "checked");
    } else if (e.target.checked === false) {
      // localStorage.removeItem("checked");
    }
  };

  // useEffect(() => {
  //   if (localStorage.checked) {
  //     setChecked(true);
  //   }
  // }, []);

  return (
    <div className="login-page">
      <div className="login-page-section">
        <div className="login-page-section-form">
          <div className="login-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
          </div>
          <form className="form_login" onSubmit={handleSubmit(login)}>
            <label className="label">
              <input
                {...register("email_or_phone", {
                  required: "Email is required",
                })}
                className="input"
                type="text"
                placeholder="Email or Phone"
                autoComplete="on"
              />
            </label>
            <label className="label">
              <input
                {...register("password", {
                  required: "password is required",
                })}
                className="input pass_field"
                type="password"
                placeholder="Password"
                autoComplete="on"
              />
            </label>
            <label className="remember_select">
              Remember me
              <input
                checked={checked}
                type="checkbox"
                onChange={(e) => handleCheckbox(e)}
              />
              <span className="checkmark"></span>
            </label>
            <button type="submit" className="login_button">
              {log_spinner}
              Sign In
            </button>
          </form>
          <div className="message-error">{message}</div>
          <div className="forget-pass-nav">
            <Link to={"/passwordrecovery"}>Forgot password?</Link>
          </div>
        </div>
        <div className="login-page-section-register">
          <div>
            Don't have an account? <Link to={"/sign-up"}>Sign up</Link>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
