import axios from "axios";

const API = axios.create({ baseURL: "https://api.oblast.io/api/" });
API.interceptors.request.use((req) => {
  if (localStorage.getItem("oblast_jwt_token")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem(
      "oblast_jwt_token"
    )}`;
  }
  return req;
});

//signin signup
export const SignIn = (data) => API.post("lo/Login/LoginStandard", data);
export const LogOut = () => API.get(`/lo/Login/LogMeOut`);
export const SignUp = (data) => API.post("/lo/Login/SignUp", data);
export const CheckJWTToken = ({ token }) =>
  API.post(`/lo/Login/CheckJWTToken`, { token });
export const VerifyYourEmail = (token) =>
  API.get("/lo/Login/VerifyEmail/?token=" + token);
export const CheckEmailForgotPass = (data) =>
  API.post("/lo/Login/CheckEmailForgotPass", data);
export const UpdatePassword = (token, data) =>
  API.post("/lo/Login/UpdatePassword/?token=" + token, data);

//user data
export const DisplayUserInfo = (userid) =>
  API.get(`/vp/ViewProfile/DisplayUserInfo?userid=${userid}`);
export const UpdateUserInfo = ({ userid, description, image }) =>
  API.post("/vp/ViewProfile/UpdateUserInfo", {
    userid,
    description,
    image,
  });
export const DisplayUserLikes = (userid) =>
  API.get(`/vp/ViewProfile/DisplayUserLikes?userid=${userid}`);
export const DisplayProfileProducts = (userid, number) =>
  API.get(
    `vp/ViewProfile/DisplayProfileProducts?userid=${userid}&skip=${number}`
  );

//search
export const SearchUsername = (username, skip) =>
  API.get(
    `/vp/ViewProfile/SearchPageUsername?username=${username}&skip=${skip}`
  );
export const SearchTimeline = (search, skip) =>
  API.get(`/ti/Timeline/Search?search=${search}&skip=${skip}`);
export const SearchUsernameFirst = (username) =>
  API.get(`/up/UserPage/Search?username=${username}`);

//follow
export const FollowUser = ({ userid, userifollow }) =>
  API.get(
    `/vp/ViewProfile/FollowUser?userid=${userid}&userifollow=${userifollow}`
  );
export const UnfollowUser = ({ userid, userifollow }) =>
  API.get(
    `/vp/ViewProfile/UnfollowUser?userid=${userid}&userifollow=${userifollow}`
  );
export const CountFollowage = (userid) =>
  API.get(`/vp/ViewProfile/CountFollowageAndLikes?userid=${userid}`);
export const DoIFollow = ({ me, whoifollow }) =>
  API.get(`/vp/ViewProfile/DoIFollow?me=${me}&whoifollow=${whoifollow}`);
export const CountFollowageAndLikes = (userid) =>
  API.get(`/vp/ViewProfile/CountFollowageAndLikes?userid=${userid}`);
export const CountLikes = (userid) =>
  API.get(`/vp/ViewProfile/CountLikes?userid=${userid}`);

//block
export const GetBlocks = () => API.get(`/vp/ViewProfile/GetBlocks`);
export const BlockUser = (other_id) =>
  API.get(`/vp/ViewProfile/BlockUser?other_id=${other_id}`);
export const UnBlockUser = (other_id) =>
  API.get(`/vp/ViewProfile/UnBlockUser?other_id=${other_id}`);

//product
export const UploadProduct = (formData) =>
  API.post("/vp/ViewProfile/UploadProduct", formData);
export const AddProduct = (data) =>
  API.post("/mph/ProdHandling/AddProduct", data);
export const DeleteProduct = (productid) =>
  API.get(`/mph/ProdHandling/DeleteProduct?productid=${productid}`);
export const LikeDislike = ({ userid, uploader_id, prod_id, value = "" }) =>
  API.get(
    `/mph/ProdHandling/LikeDislike?userid=${userid}&uploader_id=${uploader_id}&prod_id=${prod_id}&like_or_dislike=${value}`
  );
export const CountComment = (prod_id) =>
  API.get(`/mph/ProdHandling/CountComment?prod_id=${prod_id}`);
export const UpdateProduct = (data) =>
  API.post("/mph/ProdHandling/UpdateProduct", data);
export const ReportProduct = (data) =>
  API.post("/vp/ViewProfile/ReportProduct", data);
//get products
export const GetRecommended = (skip) =>
  API.get(`/mph/ProdHandling/GetRecommended?skip=${skip}`);
export const ShowSingleProduct = (prod_id) =>
  API.get(`/mph/ProdHandling/Show1Product?prod_id=${prod_id}`);
export const GetTimeline = (skip) =>
  API.get(`/ti/Timeline/GetTimeline?skip=${skip}`);

//comments
export const PostComment = (commentBody) =>
  API.post("/mph/ProdHandling/PostComment", commentBody);
export const GetComments = (prod_id) =>
  API.get(`/mph/ProdHandling/GetComment?prod_id=${prod_id}`);
export const DeleteCommentsOnMyProducts = (comment_id) =>
  API.get(
    `/mph/ProdHandling/DeleteOwnProductComments?comment_id=${comment_id}`
  );
export const DeleteCommentsIMadeSomewhere = (comment_id) =>
  API.get(
    `/mph/ProdHandling/DeleteMadeProductComments?comment_id=${comment_id}`
  );

//Boards
export const AddBoard = (productid) =>
  API.get(`/vp/ViewProfile/AddBoard?productid=${productid}`);
export const DisplayBoards = () => API.get(`/vp/ViewProfile/DisplayBoards`);
export const DeleteBoard = (boardid) =>
  API.get(`/vp/ViewProfile/DeleteBoards?boardid=${boardid}`);
