import React,{ createContext, useContext, useState } from "react";

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const [countFollowage, setCountFollowage] =useState({
    following: 0,
    followers: 0,
    likes: 0,
  })
  const [displayInfo, setDisplayInfo] = useState("");
  const [favorites, setFavorites] = useState([]);

  return (
    <DataContext.Provider
      value={{
        countFollowage, 
        setCountFollowage,
        displayInfo,
        setDisplayInfo,
        favorites,
        setFavorites,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext was used outside of its Provider");
  }
  return context;
};

export { DataContextProvider, useDataContext };
