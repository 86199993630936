import React, { useEffect, useState } from "react";
import {
  GetComments,
  PostComment,
  ShowSingleProduct,
  AddBoard,
  LikeDislike,
} from "../../../api";
import { CloseIcon } from "../../icons/CloseIcon";
import { ThreeDotsIcon } from "../../icons/ThreeDotsIcon";
import "./ProductDetailsModal.scss";
import "../../../assets/scss/Animations.scss";
import { SavedIcon } from "../../icons/SavedIcon";
import { SmileIcon } from "../../icons/SmileIcon";
import { LikeIcon } from "../../icons/LikeIcon";
import { useAuthContext } from "../../../contexts/AuthContext";
import ProductDetailsSettingsModal from "../ProductDetailsSettingsModal/ProductDetailsSettingsModal";
import Emoji from "../../../components/emoji/Emoji";
import { useNavigate } from "react-router-dom";
import ShareIconM from "../../Share/ShareIconM";
import OwnCommentsOnOthers from "../../comments/OwnCommentsOnOthers";

interface Props {
  productId?: string;
  setOpenModal: (openModal: boolean) => void;
  openSettingsModal: boolean;
  setOpenSettingsModal: (openSettingsModal: boolean) => any;
}

const ProductDetailsModal = ({
  productId,
  setOpenModal,
  openSettingsModal,
  setOpenSettingsModal,
}: Props) => {
  const { currentUser, isLoggedIn } = useAuthContext();
  const [product, setProduct] = useState<any>(null);
  const [commentValue, setCommentValue] = useState<string>("");
  const [comments, setComments] = useState<any[]>([]);
  const [commentConfig, setCommentConfig] = useState<any>({
    skip: 0,
    length: 0,
  });
  const [selectEmoji, setSelectEmoji] = useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const navigate = useNavigate();

  const likeDislike = (value: any) => {
    if (isLoggedIn === false) {
      navigate("/");
      return;
    }
    const userid = currentUser.user_ID;
    if (value === "like") {
      const uploader_id = product.uploaderId;
      const prod_id = productId;
      LikeDislike({ userid, uploader_id, prod_id })
        .then(() => {
          //show success message
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toggleHeart = (value: any) => {
    if (liked === false) {
      likeDislike("like");
    }
    setLiked(!liked);
  };

  useEffect(() => {
    ShowSingleProduct(productId)
      .then((response) => {
        if (response.status === 200) {
          setProduct(response.data);
          GetComments(response.data._id)
            .then((res) => {
              setComments(res.data);
              setCommentConfig({
                skip: commentConfig?.skip + 20,
                length: commentConfig?.length - 20,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          alert("SOMETHING WENT WRONG!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const postComments: any = () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    if (commentValue.length > 2 && commentValue.length < 500) {
      let data = {
        userid_of_commentor: currentUser.user_ID,
        product_id: productId,
        comment_username: currentUser.username,
        comment: commentValue,
        date: new Date(),
        prod_uploader_id: product.uploaderId,
      };
      PostComment(data)
        .then(() => {
          setCommentValue("");
          GetComments(productId)
            .then((res: any) => {
              setComments(res.data);
              setCommentConfig({
                skip: commentConfig?.skip + 20,
                length: commentConfig?.length - 20,
              });
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      alert("Characters length should be greater than 2");
    }
  };

  const handleSettingsModalClick = () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    setOpenSettingsModal(true);
  };

  const handleSaved = () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    AddBoard(productId)
      .then((r) => {})
      .catch((er) => {
        console.log(er, "error adding to board");
      });
  };

  const toggleSaved = (value: any) => {
    setSaved(!saved);
    handleSaved();
  };

  const onCommentDelete = () => {
    //refresh here the comments
    GetComments(productId)
      .then((res: any) => {
        setComments(res.data);
        setCommentConfig({
          skip: commentConfig?.skip + 20,
          length: commentConfig?.length - 20,
        });
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  return (
    <div className="modal">
      <div className="close-modal" onClick={() => setOpenModal(false)}>
        <CloseIcon />
      </div>
      <div className="modal-content">
        <div className="product-details-modal">
          <div className="product-image">
            <img src={product?.Images} alt="img" />
          </div>
          <div className="product-comments-section">
            <div className="comments-body">
              <div className="comments-header">
                <div className="comments-header-username">
                  <img
                    onClick={() => navigate(`/u/${product?.username}`)}
                    src={product?.profile_picture}
                    alt="avatar"
                  />
                  <h4
                    className="user-name"
                    onClick={() => {
                      navigate(`/u/${product?.username}`);
                    }}
                  >
                    {product?.username}
                  </h4>
                </div>
                <div
                  className="comments-header-icon"
                  onClick={() => handleSettingsModalClick()}
                >
                  <ThreeDotsIcon />
                </div>
              </div>
              {product?.Description && (
                <div className="comments-description-section">
                  <div>
                    <div>
                      <span className="title">
                        {product?.Title.substring(0, 200) + "..."}
                      </span>
                      <span className="description">
                        {product?.Description.substring(0, 800) + "..."}
                      </span>
                      <br />
                      {product?.Url && (
                        <button
                          onClick={() =>
                            window.open(product?.Url, "_blank", "noreferrer")
                          }
                        >
                          [View Product Source]
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="user-comment">
                {comments.map((comment) => {
                  return (
                    <OwnCommentsOnOthers
                      onDeleteRefresh={onCommentDelete}
                      comment_id={comment.id}
                      comment_username={comment?.comment_username}
                      comment_text={comment.comment}
                      userid_of_commentor={comment.userid_of_commentor}
                      my_user_id={currentUser.user_ID}
                    />
                  );
                })}
              </div>
            </div>
            <div className="comments-footer">
              <div className="icons-section">
                <div
                  className={`${liked ? "liked-pulse" : ""}`}
                  onClick={toggleHeart}
                >
                  <LikeIcon />
                </div>
                {/* <div className="like">
                  <span onClick={() => likeDislike("like")}>Like it?</span>
                </div> */}
                <ShareIconM product={productId} />
                <div
                  onClick={toggleSaved}
                  className={`${saved ? "saved-pulse" : ""}`}
                >
                  <SavedIcon />
                </div>
              </div>
              <div className="add-comment-left">
                <div
                  onClick={() => {
                    setSelectEmoji(!selectEmoji);
                  }}
                  className="emoji-open-icon"
                >
                  <SmileIcon />
                  {selectEmoji && (
                    <div className="emoji-section">
                      <Emoji
                        addEmoji={(emoji: any) => {
                          setCommentValue(commentValue + emoji);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <textarea
                    placeholder="Add a comment..."
                    value={commentValue}
                    onChange={(e) => setCommentValue(e.target.value)}
                  />
                </div>
                <div className="circle-button">
                  <div></div>
                  <button onClick={postComments}>[Post]</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSettingsModal ? (
        <ProductDetailsSettingsModal
          setOpenSettingsModal={setOpenSettingsModal}
        />
      ) : null}
    </div>
  );
};

export default ProductDetailsModal;
