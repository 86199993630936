import React, {useEffect, useState} from "react";
import {DisplayBoards, DisplayUserInfo, GetComments, GetRecommended, GetTimeline} from "../../api";
import {useAuthContext} from "../../contexts/AuthContext";
import "./TimelinePage.scss";
import TimelineCard from "../../components/TimelineCard/TimelineCard";
import ProductDetailsModal from "../../components/modals/ProductDetailsModal/ProductDetailsModal";
import ProductDetailsSettingsModal from "../../components/modals/ProductDetailsSettingsModal/ProductDetailsSettingsModal";
import ProblemReportModal from "../../components/modals/ProblemReportModal/ProblemReportModal";
import {BeatLoader, PulseLoader} from "react-spinners";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import {useNavigate} from "react-router-dom";
import MiniUserModal from "../../components/modals/MiniUserModal/MiniUserModal";
const TimelinePage = () => {
  const {currentUser, isLoggedIn} = useAuthContext();
  const [products, setProducts] = useState<any[]>([]);
  const [fetcProductData, setFetcProductData] = useState<boolean>(false);
  const [skip, setSkip] = useState<number>(0);
  const [commentSkip, setCommentSkip] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [openProblemReportModal, setOpenProblemReportModal] = useState<boolean>(false);
  const [boards, setBoards] = useState<[]>([]);
  const [showBoards, setShowBoards] = useState<[]>([]);
  const [showLength, setShowLength] = useState<number>(6);
  const [usersId, setUsersId] = useState<[]>([]);
  const [users, setUsers] = useState<[]>([]);
  const boardLength = boards.length;
  const [user, setUser] = useState<any>(null);
  const [coords, setCoords] = useState<any>({x: 0, y: 0});
  const [lastCoords, setLastCoords] = useState<any>({x: 0, y: 0});
  const [openMiniUserModal, setOpenMiniUserModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const GetTimelineOut = async (skip_me: number) => {
    console.log("pulling data from timeline");
    await GetTimeline(skip_me)
      .then(async (response: any) => {
        const {data} = response;
        // console.log(data, "data");
        let myArray = products;
        myArray.push(...data);
        setProducts(myArray);
        setFetcProductData(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchProductData = async () => {
      setFetcProductData(true);
      GetTimelineOut(skip);
    };

    if (currentUser !== null) {
      fetchProductData();
    }
  }, [currentUser]);

  useEffect(() => {
    console.log(currentUser, "current user");
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
  }, []);

  const handleSettingsHover = () => {
    setOpenMiniUserModal(true);
  };

  const handleSettingsHoverOff = () => {
    setOpenMiniUserModal(false);
  };
  const handleWindowMouseMove = (event: any) => {
    setCoords({
      x: event.clientX,
      y: event.clientY,
    });
  };
  window.addEventListener("mousemove", handleWindowMouseMove);

  const handleScroll = (e: any) => {
    const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1;
    if (bottom) {
      console.log("reached the bottom scroll");
      let new_skip = skip + 1;
      let skiper = new_skip * 15;
      setSkip(new_skip);
      GetTimelineOut(skiper);
    }
  };

  return (
    <div className="timeline-page ">
      <div className="container">
        {fetcProductData ? (
          <div className="spinner">
            <PulseLoader color="#0095f6" />
          </div>
        ) : (
          <div className="timeline-section" id="scroll_div" onScroll={handleScroll}>
            {products?.map((product, index) => {
              return (
                <TimelineCard
                  index={index}
                  setProducts={setProducts}
                  products={products}
                  product={product}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  openSettingsModal={openSettingsModal}
                  setOpenSettingsModal={setOpenSettingsModal}
                  setActiveProductId={setActiveProductId}
                  key={product._id}
                  users={users}
                />
              );
            })}
          </div>
        )}
      </div>
      {openModal && (
        <ProductDetailsModal
          productId={activeProductId}
          setOpenModal={setOpenModal}
          setOpenSettingsModal={setOpenSettingsModal}
          openSettingsModal={openSettingsModal}
        />
      )}
      {openSettingsModal && <ProductDetailsSettingsModal setOpenSettingsModal={setOpenSettingsModal} productId={activeProductId} />}
      {openProblemReportModal && <ProblemReportModal setOpenProblemReportModal={setOpenProblemReportModal} />}
      {openMiniUserModal && <MiniUserModal setOpenMiniUserModal={setOpenMiniUserModal} user={user} coords={coords} lastCoords={lastCoords} />}
    </div>
  );
};

export default TimelinePage;
