import React, { useState, useEffect } from "react";
import "../modals/ProductDetailsModal/ProductDetailsModal.scss";
import { DeleteCommentsIMadeSomewhere } from "../../api";
import { useNavigate } from "react-router-dom";

interface Props {
  comment_id?: string;
  comment_username?: string;
  comment_text?: string;
  userid_of_commentor?: string;
  my_user_id?: string;
  onDeleteRefresh: () => void;
}

const OwnCommentsOnOthers = ({
  comment_id,
  comment_username,
  comment_text,
  userid_of_commentor,
  my_user_id,
  onDeleteRefresh,
}: Props) => {
  const [removal, setRemoval] = useState<string>("[x]");
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (userid_of_commentor === my_user_id) {
  //     console.log(
  //       "comment: ",
  //       comment_text,
  //       " commentor user: ",
  //       userid_of_commentor,
  //       " my_user_id: ",
  //       my_user_id
  //     );
  //     setRemoval("[x]");
  //   }
  // }, []);

  const handleCommentDelete = () => {
    if (removal === "[x]") {
      setRemoval("[delete]");
    } else if (removal === "[delete]") {
      setRemoval("[x]");
      // setTimeout(() => {
      //   setRemoval("deleted\nrefesh");
      // }, 5000);
      DeleteCommentsIMadeSomewhere(comment_id)
        .then((res: any) => {
          //refresh here
          onDeleteRefresh();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  return (
    <div key={comment_id}>
      <div className="comment-row">
        <div>
          <span
            onClick={() => {
              navigate(`/u/${comment_username}`);
              window.location.reload();
            }}
            className="username"
          >
            {comment_username}
          </span>
          <span className="comment">{comment_text}</span>
        </div>
      </div>
      <div onClick={handleCommentDelete} className="remove-comment">
        {userid_of_commentor === my_user_id ? removal : ""}
      </div>
    </div>
  );
};

export default OwnCommentsOnOthers;
