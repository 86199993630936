import React, { useEffect, useState, useRef } from "react";
import "./ProfilePage.scss";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  DisplayProfileProducts,
  CountFollowageAndLikes,
  DisplayUserInfo,
} from "../../api";
import { WhiteHeartIcon } from "../../components/icons/WhiteHeartIcon";
import { CommentsIconWhite } from "../../components/icons/CommentsIconWhite";
import ProfileSettingsModal from "../../components/modals/ProfileSettingsModal/ProfileSettingsModal";
import ProductDetailsModalSelf from "../../components/modals/ProductDetailsModalSelf/ProductDetailsModalSelf";
const ProfilePage = () => {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser, isLoggedIn } = useAuthContext();
  const [products, setProducts] = useState<[]>([]);
  const [follows, setFollows] = useState<any>({});
  const [openProductModal, setOpenProductModal] = useState<boolean>(false);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [openProfileSettingsModal, setOpenProfileSettingsModal] =
    useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const ref = useRef<any>(null);
  const [page, setPage] = useState<number>(0);

  const fetchData = async (skip: number) => {
    await DisplayProfileProducts(currentUser.user_ID, skip)
      .then(async (res) => {
        console.log(res, "displayprofileproducts");
        if (res.data !== "User hasn't added any products") {
          let prods: any = [...products, ...res.data];
          setProducts(prods);
        }
      })
      .catch((err) => {
        console.log(err, "Product not found");
      });
  };

  const PullSelfInfo = async () => {
    if (currentUser !== null) {
      DisplayUserInfo(currentUser.user_ID)
        .then(async (res) => {
          const userData = {
            user_ID: currentUser.user_ID,
            username: res?.data.username,
            image: res?.data.image,
            description: res?.data.description,
          };
          setCurrentUser(userData);
        })
        .catch((err) => {
          console.log("errrrr", err);
        });
    }
  };

  const UpdateLikesFollows = async (user_id: string) => {
    await CountFollowageAndLikes(user_id)
      .then((res) => {
        console.log(res.data, "countfollowageandlikes");
        setFollows(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (currentUser !== null) {
      fetchData(page);
      console.log("current user: ", currentUser);
      setUser(currentUser.user_ID);
      UpdateLikesFollows(currentUser.user_ID);
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    PullSelfInfo();
  }, []);

  const handleProductClick = (index: string) => {
    setOpenProductModal(true);
    setActiveProductId(index);
  };

  const handleScroll = (e: any) => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
      ) <= 1;
    if (bottom) {
      console.log("reached the bottom scroll");
      let new_skip = page + 1;
      let skiper = new_skip * 15;
      setPage(new_skip);
      fetchData(skiper);
    }
  };

  const remove_delete_product = (id: String) => {
    let new_products: any = products?.filter((item: any) => item._id !== id);
    setProducts(new_products);
  };

  const convertLinks = (desc: string) => {
    if (!desc) return "";
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return desc.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
  };

  const description = convertLinks(currentUser?.description);

  return (
    <div className="profile-page">
      <div className="profile-page-container">
        <div className="letters">
          <p>{currentUser?.username}</p>
        </div>
        <div className="share-section" ref={ref} onScroll={handleScroll}>
          <div className="header-section">
            <div className="profile-info">
              <div className="top-row">
                <div className="profile-pic-section">
                  <img
                    src={
                      currentUser?.image === ""
                        ? defaultAvatar
                        : currentUser?.image
                    }
                    alt="user-avatar"
                  />
                </div>
                <div className="likes-and-stuff">
                  <div className="lff">
                    <span className="count">
                      {follows ? follows?.likes : 0}
                    </span>
                    <span className="text">likes</span>
                  </div>
                  <div className="lff">
                    <span className="count">
                      {follows ? follows?.follows_me : 0}
                    </span>
                    <span className="text">followers</span>
                  </div>
                  <div className="lff">
                    <span className="count">
                      {follows ? follows?.i_follow : 0}
                    </span>
                    <span className="text">following</span>
                  </div>
                </div>
              </div>
              <div className="middle-row">
                <div>
                  <span
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></span>
                </div>
              </div>
              <div className="bottom-row">
                <div className="edit-button">
                  <button
                    onClick={() => {
                      navigate("/settings-page");
                    }}
                  >
                    Edit profile
                  </button>
                </div>
              </div>
            </div>
          </div>
          {products ? (
            <div className="publications_body">
              {products?.map((item: any) => {
                return (
                  <div
                    className="publication_div"
                    key={item.id}
                    onClick={() => handleProductClick(item._id)}
                  >
                    <div className="image-container">
                      <img src={item?.Images} alt="product" />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>no publications</div>
          )}
        </div>
      </div>
      {openProfileSettingsModal && (
        <ProfileSettingsModal
          setOpenProfileSettingsModal={setOpenProfileSettingsModal}
          user={user}
          setUser={setUser}
        />
      )}
      {openProductModal && (
        <ProductDetailsModalSelf
          productId={activeProductId}
          setOpenModal={setOpenProductModal}
          openSettingsModal={openSettingsModal}
          setOpenSettingsModal={setOpenSettingsModal}
          removeDeleteProduct={remove_delete_product}
        />
      )}
    </div>
  );
};
export default ProfilePage;
