import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import ChangePasswordPage from "../pages/ChangePasswordPage/ChangePasswordPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage/ForgetPasswordPage";
import ForgetPasswordSecondPage from "../pages/ForgetPasswordSecondPage/ForgetPasswordSecondPage";
import LoginPage from "../pages/loginPage/LoginPage";
import SignupPage from "../pages/SignupPage/SignupPage";
import TimelinePage from "../pages/TimelinePage/TimelinePage";
import VerifyEmailPage from "../pages/VerifyEmailPage/VerifyEmailPage";
// import PublicationPage from "../pages/PublicationPage/PublicationPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import ActionsPage from "../pages/Actions/ActionsPage";
import MessagePage from "../pages/MessagesPage/MessagePage";
import FavoritesPage from "../pages/FavoritesPage/FavoritesPage";
import FriendsProfile from "../pages/FriendsProfile/FriendsProfile";
// import ViewProduct from "../pages/ViewProduct/ViewProduct";
import DiscoveryPage from "../pages/DiscoveryPage/DiscoveryPage";
import DiscoveryPageShare from "../pages/DiscoveryPage/DiscoveryPageShare";
import SearchPage from "../pages/SearchPage/SearchPage";
import { AddProductPage } from "../pages/AddProductPage/AddProductPage";
import VerifyTokenPage from "../pages/VerifyTokenPage/VerifyTokenPage";

const Router = () => {
  const PrivateRoute = ({ children }) => {
    const oblastJwtToken = localStorage.getItem("oblast_jwt_token");

    if (!oblastJwtToken) {
      return <Navigate to="/" />;
    }

    return children;
  };

  const PublicRoute = ({ children }) => {
    const oblastJwtToken = localStorage.getItem("oblast_jwt_token");

    if (oblastJwtToken) {
      return <Navigate to="/discovery" />;
    }

    return children;
  };

  return (
    <div className="main-app oblast-app">
      <BrowserRouter>
        <Sidebar />
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <PublicRoute>
                <SignupPage />
              </PublicRoute>
            }
          />
          <Route
            path="/discovery"
            element={
              <DiscoveryPage />
              // <PrivateRoute>
              // </PrivateRoute>
            }
          />
          <Route
            path="/timeline"
            element={
              <TimelinePage />
              // <PublicRoute>
              // </PublicRoute>
            }
          />
          <Route
            path="/verifyemail"
            element={
              <PublicRoute>
                <VerifyEmailPage />
              </PublicRoute>
            }
          />
          <Route
            path="/verifytoken"
            element={
              <PublicRoute>
                <VerifyTokenPage />
              </PublicRoute>
            }
          />
          <Route path="/passwordrecovery" element={<ForgetPasswordPage />} />
          <Route
            path="/passwordrecovery2"
            element={<ForgetPasswordSecondPage />}
          />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          {/* <Route path="/publication-page/:id" element={<PublicationPage />} /> */}
          <Route
            path="/settings-page"
            element={
              <PrivateRoute>
                <SettingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile-page"
            element={
              <ProfilePage />
              // <PrivateRoute>
              // </PrivateRoute>
            }
          />
          <Route
            path="/actions-page"
            element={
              <PrivateRoute>
                <ActionsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/message-page"
            element={
              <PrivateRoute>
                <MessagePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/favorites-page"
            element={
              <FavoritesPage />
              // <PrivateRoute>
              // </PrivateRoute>
            }
          />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/u/:username" element={<FriendsProfile />} />
          <Route path="/d/:product" element={<DiscoveryPageShare />} />
          <Route
            path="/add"
            element={
              <PrivateRoute>
                <AddProductPage />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/product/:id"
            element={
              <PrivateRoute>
                <ViewProduct />
              </PrivateRoute>
            }
          /> */}
          {/* <Route path="/*" element={<Navigate replace to="/" />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Router;
