import React, { useEffect, useState } from "react";
import "./DiscoveryPage.scss";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { SavedIcon } from "../../components/icons/SavedIcon";
import {
  AddBoard,
  GetComments,
  GetRecommended,
  LikeDislike,
  PostComment,
} from "../../api";
import { useProductContext } from "../../contexts/ProductContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { ThreeDotsIcon } from "../../components/icons/ThreeDotsIcon";
import { HeartIcon } from "../../components/icons/HeartIcon";
import { CommentIcon } from "../../components/icons/CommentsIcon";
import ShareIconM from "../../components/Share/ShareIconM";
import { SmileIcon } from "../../components/icons/SmileIcon";
import Emoji from "../../components/emoji/Emoji";
import { LikedIcon } from "../../components/icons/LikedIcon";
import moment from "moment";
import ProductDetailsModal from "../../components/modals/ProductDetailsModal/ProductDetailsModal";
import ProductDetailsSettingsModal from "../../components/modals/ProductDetailsSettingsModal/ProductDetailsSettingsModal";

export const DiscoveryPage = () => {
  const [count, setCount] = useState<number>(0);
  const [skipCounter, setSkipCounter] = useState<number>(0);
  const { products, setProducts, product, setProduct } = useProductContext();
  const navigate = useNavigate();
  const [favorite, setFavorite] = useState<boolean>(false);
  const { currentUser, isLoggedIn } = useAuthContext();
  const [commentValue, setCommentValue] = useState<string>("");
  const [openEmojiSelect, setOpenEmojiSelect] = useState<boolean>(false);
  const [commentConfig, setCommentConfig] = useState<any>({
    skip: 0,
    length: 0,
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [saved, setSaved] = useState<boolean>(false);

  const [openProblemReportModal, setOpenProblemReportModal] =
    useState<boolean>(false);
  const [liked, setLiked] = useState<boolean>(false);

  console.log(isLoggedIn, "isloggedin 40 outside function, render every time");

  const likeDislike = (value: any) => {
    if (isLoggedIn === false) {
      navigate("/");
      return;
    }
    setCommentValue("");
    setFavorite(false);
    const userid = currentUser.user_ID;
    if (value === "dislike") {
      setCount(count + 1);
      if (count + 1 <= products.length) {
        setProduct(products[count + 1]);
      }
      if (count !== 0 && count % 9 === 0) {
        setCount(0);
        setSkipCounter(skipCounter + 10);
        GetRecommended(skipCounter)
          .then(async (response) => {
            setProducts(response.data);
            setProduct(response.data[0]);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      const uploader_id = products[count].uploaderId;
      const prod_id = products[count]._id;
      LikeDislike({ userid, uploader_id, prod_id })
        .then(() => {
          setCount(count + 1);
          if (count + 1 <= products.length) {
            setProduct(products[count + 1]);
          }
          if (count !== 0 && count % 9 === 0) {
            setCount(0);
            setSkipCounter(skipCounter + 10);
            GetRecommended(skipCounter)
              .then(async (response) => {
                const { data } = response;
                setProducts(response.data);
                setProduct(response.data[0]);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const toggleHeart = (value: any) => {
    if (liked === false) {
      likeDislike("like");
    }
    setLiked(!liked);
  };

  const postComments: any = () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    if (commentValue.length > 2) {
      if (product.comment_count > 0) {
        product.comment_count += 1;
      } else {
        product.comment_count = 1;
      }
    }
    if (commentValue.length > 2) {
      let data = {
        userid_of_commentor: currentUser.user_ID,
        product_id: product._id,
        comment_username: currentUser.username,
        comment: commentValue,
        date: new Date(),
        prod_uploader_id: product.uploaderId,
      };
      PostComment(data)
        .then(() => {
          setCommentValue("");
          GetComments(product._id)
            .then((res: any) => {
              const newProduct = { ...product };
              newProduct.comments = res.data;
              setProduct(newProduct);
              setCommentConfig({
                skip: commentConfig?.skip + 20,
                length: commentConfig?.length - 20,
              });
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          console.log(error);
        });
    } else {
      alert("Characters length should be greater than 2");
    }
  };

  const getRecommend = async (skiper: number) => {
    await GetRecommended(skiper)
      .then(async (response) => {
        setProducts(response.data);
        setProduct(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRecommend(0);
  }, []);

  if (!product || !Object.keys(product).length) {
    return (
      <div className="spinner">
        <PulseLoader color="#0095f6" />
      </div>
    );
  }

  const handleCommentClick = (index: string) => {
    setOpenModal(true);
    setActiveProductId(index);
  };

  const handleSettingsClick = (index: string) => {
    setOpenSettingsModal(true);
    setActiveProductId(index);
  };

  const handleSaved = async () => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    await AddBoard(product._id)
      .then((res) => {
        console.log(res, "res");
      })
      .catch((er) => {
        console.log(er, "error adding to board");
      });
  };

  const toggleSaved = (value: any) => {
    setSaved(!saved);
    handleSaved();
  };

  return (
    <div className="discovery-page">
      <div className="view-product-discovery">
        <div className="discovery-card" key={product._id}>
          <div className="discovery-card-header">
            <div onClick={() => navigate(`/u/${product?.username}`)}>
              <div className="discovery-card-header-avatar">
                <img
                  src={
                    product?.profile_picture
                      ? product?.profile_picture
                      : defaultAvatar
                  }
                  alt="avatar"
                />
              </div>
              <div
                className="user-info"
                // onMouseLeave={() => {
                //   handleSettingsHoverOff();
                // }}
              >
                <h4
                  className="user-name"
                  //   onMouseEnter={(e) => {
                  //     setUser(e);
                  //     handleSettingsHover();
                  //     setLastCoords({ x: 0, y: 0 });
                  //   }}
                  //   onMouseLeave={() => {
                  //     setLastCoords({ ...coords });
                  //   }}
                >
                  {product?.username}
                </h4>
                <p className="date">
                  {/* {products?.date ? products?.date : "5 HOURS AGO"} */}•{" "}
                  {!products.date
                    ? "15h"
                    : moment(product.date).format("ddd MMM D | h:mm A")}
                </p>
              </div>
            </div>
            <div
              className="three-dots"
              onClick={() => handleSettingsClick(product._id)}
            >
              <ThreeDotsIcon />
            </div>
          </div>
          <div className="discovery-card-body">
            <div
              className="discovery-product-image"
              onClick={() => console.log(products, "products")}
            >
              <img src={product?.Images || defaultAvatar} alt="product" />
            </div>
            <div className="product-actions">
              <div className="left-side-action">
                {/* <div className="like">{!product.liked ? <HeartIcon /> : <LikedIcon />}</div> */}
                <div
                  className={`heart ${liked ? "liked" : ""}`}
                  onClick={toggleHeart}
                ></div>
                <div
                  className="comment"
                  onClick={() => handleCommentClick(product._id)}
                >
                  <CommentIcon />
                </div>
                <ShareIconM product={product._id} />
                {/* <div className="share">
                </div> */}
              </div>
              <div
                className={`save-post ${saved ? "saved-pulse" : ""}`}
                onClick={toggleSaved}
              >
                <SavedIcon />
              </div>
            </div>
            <div className="product-desc">
              <p className="desc">
                <b
                  className="user-name"
                  onClick={() => navigate(`/u/${product?.username}`)}
                >
                  {product?.username?.length
                    ? product.username.length > 16
                      ? `${product.username.substring(0, 16)}`
                      : product.username
                    : "No Username"}
                </b>
                {product?.Title.length > 60 ? (
                  <>
                    <span onClick={() => handleCommentClick(product._id)}>
                      {product?.Title.substring(0, 60) + "..."}
                    </span>
                  </>
                ) : (
                  <>
                    <span onClick={() => handleCommentClick(product._id)}>
                      {product?.Title}
                    </span>
                  </>
                )}
              </p>
            </div>
            <div className="product-comments">
              {product?.comment_count > 0 ? (
                <div
                  className="view-comments"
                  onClick={() => handleCommentClick(product._id)}
                >
                  <p>View all {product.comment_count} comments</p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="add-comment">
              <input
                type="text"
                placeholder="Write your comment here..."
                value={commentValue}
                onChange={(e) => setCommentValue(e.target.value)}
              />
              <div className="add_comment_icon" onClick={postComments}>
                Send
              </div>
              <div
                className="emoji-icon"
                onClick={() => {
                  setOpenEmojiSelect(!openEmojiSelect);
                }}
              >
                <SmileIcon />
                {openEmojiSelect && (
                  <div className="emoji-section">
                    <Emoji
                      height="100%"
                      width="100%"
                      addEmoji={(emoji: any) => {
                        setCommentValue(commentValue + emoji);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* {openMiniUserModal && (
        <MiniUserModal
          user={users}
          setOpenMiniUserModal={setOpenMiniUserModal}
          coords={coords}
          lastCoords={lastCoords}
        />
      )} */}
        </div>
        <div className="bottom-section">
          <div className="dislike">
            <span
              onClick={() => {
                setLiked(false);
                likeDislike("dislike");
              }}
            >
              Dislike
            </span>
          </div>
          <div className="like">
            <span
              onClick={() => {
                setLiked(false);
                likeDislike("like");
              }}
            >
              Like
            </span>
          </div>
        </div>
      </div>
      {openModal && (
        <ProductDetailsModal
          productId={activeProductId}
          setOpenModal={setOpenModal}
          setOpenSettingsModal={setOpenSettingsModal}
          openSettingsModal={openSettingsModal}
        />
      )}
      {openSettingsModal && (
        <ProductDetailsSettingsModal
          setOpenSettingsModal={setOpenSettingsModal}
          productId={activeProductId}
        />
      )}
    </div>
  );
};
export default DiscoveryPage;
