import React, { useEffect, useState } from "react";
import "./FavoritesPage.scss";
import { HeartIcon } from "../../components/icons/HeartIcon";
import { DisplayBoards, DisplayUserInfo, GetRecommended } from "../../api";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import { BeatLoader } from "react-spinners";
import MiniUserModal from "../../components/modals/MiniUserModal/MiniUserModal";
import { useNavigate } from "react-router-dom";
import ProductDetailsModal from "../../components/modals/ProductDetailsModal/ProductDetailsModal";
import ProductDetailsSettingsModal from "../../components/modals/ProductDetailsSettingsModal/ProductDetailsSettingsModal";
import { useAuthContext } from "../../contexts/AuthContext";

const FavoritesPage = () => {
  const [boards, setBoards] = useState<[]>([]);
  const [showBoards, setShowBoarda] = useState<[]>([]);
  const [showLength, setShowLength] = useState<number>(6);
  const [usersId, setUsersId] = useState<[]>([]);
  const [users, setUsers] = useState<[]>([]);
  const boardLength = boards.length;
  const [user, setUser] = useState<any>(null);
  const [coords, setCoords] = useState<any>({ x: 0, y: 0 });
  const [lastCoords, setLastCoords] = useState<any>({ x: 0, y: 0 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();

  const handleFavClick = (index: string) => {
    console.log("handlefavclick clicked");
    setActiveProductId(index);
    setOpenModal(true);
  };

  const handleWindowMouseMove = (event: any) => {
    setCoords({
      x: event.clientX,
      y: event.clientY,
    });
  };
  window.addEventListener("mousemove", handleWindowMouseMove);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
      return;
    }
    const fetchData = async () => {
      await DisplayBoards()
        .then((res) => {
          setBoards(res.data.reverse());
          setShowBoarda(res.data.slice(0, showLength));
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchData();
  }, [showLength]);

  return (
    <div id="general-page" className="favorites-page">
      <div className="favorites-section">
        <div className="header-section">
          <h1>Saved</h1>
        </div>
        {showBoards.length === 0 ? (
          <div className="middle-section">
            <div className="favorites-icon">
              <HeartIcon />
            </div>
            <span>Actions on your posts</span>
            <span>Likes and comments on your posts will be shown here.</span>
          </div>
        ) : (
          <div className="favorites">
            {showBoards?.map((e: any) => {
              return (
                <div
                  key={e?._id}
                  className="board_favorites"
                  onClick={() => {
                    handleFavClick(e?.productid);
                  }}
                >
                  <div className="avatar_section">
                    {e.image ? (
                      <div>
                        <img src={e?.image} alt="img" />
                      </div>
                    ) : (
                      <div>
                        <img src={defaultAvatar} alt="avatar" />
                      </div>
                    )}
                  </div>
                  <div className="info_section">
                    <p>{e?.title}</p>
                    <span>{e?.last_added}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {openModal && (
        <ProductDetailsModal
          productId={activeProductId}
          setOpenModal={setOpenModal}
          setOpenSettingsModal={setOpenSettingsModal}
          openSettingsModal={openSettingsModal}
        />
      )}
    </div>
  );
};

export default FavoritesPage;
