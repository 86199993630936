import React, { useState } from "react";
import "./ProblemReportModal.scss";
import { CloseIcon } from "../../icons/CloseIcon";
import { MarksIcon } from "../../icons/MarksIcon";
interface Props {
  setOpenProblemReportModal: (openProblemReportModal: boolean) => void;
}
const ProblemReportModal = ({ setOpenProblemReportModal }: Props) => {
  const [textareaVal, setTextareaVal] = useState<string>("");
  const [image, setImage] = useState<any>(null);
  const [active, setActive] = useState<boolean>(false);

  const getImage = (file: any) => {
    if (file.length) {
      setImage(file[0]);
    }
  };

  return (
    <div className="modal">
      <div
        className="close-modal"
        onClick={() => setOpenProblemReportModal(false)}
      >
        <CloseIcon />
      </div>
      <div className="modal-content">
        <div className="modal-block">
          <div className="modal-header">
            <h4>Problem Report</h4>
          </div>
          <div className="text-section">
            <textarea
              placeholder="Describe the problem in as much detail as possible..."
              value={textareaVal}
              onChange={(e) => setTextareaVal(e.target.value)}
            />
          </div>
          {image && (
            <div className="upload-file">
              {image && (
                <div>
                  <img src={URL.createObjectURL(image)} alt="img"></img>
                  <div onClick={() => setImage(!image)}>
                    <CloseIcon />
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="send-add">
            {textareaVal ? (
              <button className="send">Send report</button>
            ) : (
              <button className="send" disabled>
                Send report
              </button>
            )}
            <div className="add">
              <label className="upload-file">
                add file
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setActive(!active);
                    getImage(e.target.files);
                  }}
                />
              </label>
            </div>
          </div>
          <div className="info">
            <p>Your username will be automatically included in your post.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemReportModal;
