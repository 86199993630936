export const InteractionsIcon = () => {
  return (
    <svg
      aria-label="Interactions: View and remove likes, comments, and other interactions you have."
      className="actions-icons"
      color="rgb(38, 38, 38)"
      fill="rgb(38, 38, 38)"
      height="24"
      role="img"
      viewBox="0 0 24 24"
      width="24"
    >
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        x1="2.369"
        x2="22.25"
        y1="16.803"
        y2="16.803"
      ></line>
      <polyline
        fill="none"
        points="17.322 11.855 22.25 16.803 17.322 21.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></polyline>
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        x1="21.631"
        x2="1.75"
        y1="7.197"
        y2="7.197"
      ></line>
      <polyline
        fill="none"
        points="6.678 12.145 1.75 7.197 6.678 2.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      ></polyline>
    </svg>
  );
};
