import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import "./ProfileSettingsModal.scss";
import {BlockUser, UnBlockUser} from "../../../api";

interface Props {
  setOpenProfileSettingsModal: (openProfileSettingsModal: boolean) => void;
  setUser: (user: any) => void;
  user: any;
}

const ProfileSettingsModal = ({
  setOpenProfileSettingsModal,
  setUser,
  user,
}: //   setBlocking,
//   blocking,
//   productId,
Props) => {
  const navigate = useNavigate();
  const [blocking, setBlocking] = useState<boolean>(true);

  const handleBlock = async () => {
    await BlockUser(user._id)
      .then((res) => {
        console.log(res, "res");
        setBlocking(false);
      })
      .catch((err) => {
        console.log(err);
        setBlocking(false);
      });
    console.log(blocking, "blocking block");
  };

  const handleUnBlock = async () => {
    await UnBlockUser(user._id)
      .then((res) => {
        console.log(res, "res");
        setBlocking(true);
      })
      .catch((err) => {
        console.log(err);
        setBlocking(true);
      });
    console.log(blocking, "blocking unblock");
  };

  return (
    <div className="modal profile-settings">
      <div className="modal-content">
        <div className="settings-modal">
          <div className="general-row">{blocking ? <p onClick={() => handleBlock()}>Block</p> : <p onClick={() => handleUnBlock()}>Unblock</p>}</div>
          <div onClick={() => setOpenProfileSettingsModal(false)}>
            <p>Cancel</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettingsModal;
