import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UpdatePassword } from "../../api";
import lightLogo from "../../assets/images/logolight.png";
import "./ChangePasswordPage.scss";
import "../../assets/scss/Animations.scss";

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [message, setMessage] = useState<string>("");
  const [log_spinner, setLog_spinner] = useState<any>("");

  const currentLocation = window.location.href.split("?forgottoken=");

  const changePassword = (data: any) => {
    if (data.password !== data.confirmPassword) {
      alert("The passwords do not match");
    } else {
      setLog_spinner(<div className="loader"></div>);
      let tokenx: string = "";
      if (currentLocation.length > 0) {
        tokenx = currentLocation[1];
        UpdatePassword(tokenx, data)
          .then((response) => {
            setLog_spinner("");
            navigate("/");
            reset();
          })
          .catch((error) => {
            console.log(error);
            setMessage("error verifying your identity");
            setLog_spinner("");
          });
      } else {
        setMessage("no token to verify");
      }
    }
  };

  return (
    <div className="change-page">
      <div className="change-page-section">
        <div className="change-page-section-form">
          <div className="change-page-section-form-title">
            <img src={lightLogo} alt="lightLogo" />
            <h3>Change Password</h3>
          </div>
          <div className="change-page-section-form-description">
            <p>Enter 8-256 characters as your new password.</p>
            <p>
              Do not include common words or names.
              <br />
              Combine uppercase letters, lowercase letters, numbers, and
              symbols.
            </p>
          </div>
          <form
            onSubmit={handleSubmit(changePassword)}
            className="content_section"
          >
            <div className="set_password"></div>
            <div className="description_section"></div>
            <div className="label">
              <input
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
                placeholder="New Password"
                type="password"
                className="input"
              />
            </div>
            <div className="label">
              <input
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                })}
                placeholder="Confirm New Password"
                type="password"
                className="input"
              />
            </div>
            <button className="send-btn" type="submit">
              {log_spinner}
              Submit
            </button>
          </form>
          <p>
            Need help? <b> Contact Us</b>
          </p>
          <div>{message}</div>
        </div>
        <div className="change-page-section-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => navigate("/")}
          >
            Back to Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
